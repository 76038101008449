<div class="km-sign-in-container">


    <app-navbar></app-navbar>

    <div [ngStyle]="{'display':showSignin == true ? 'block':'none'}" style="max-height:405px;" class="km-signin-window"
        [ngClass]="{'km-signup-holder-view':currentView == 'holder','km-signup-password-view': currentView =='pass' }">

        <div style="opacity:0;transition: opacity 0.35s ease-in-out;"
            [ngStyle]="{'opacity':showSignInAnimated == true ? '1':'0'}">


            <div style="margin-top:1px;">
                <span class="km-sign-in-text">{{getStateText()}}</span>
            </div>

            <div style="margin-top: 10px;display: flex;">
                <div style="display:inline-block;width:33.3%;margin-right:2px;">
                    <div class="km-progress-bar-parent" style="cursor: pointer;">
                        <div class="km-progress-bar-child" [ngStyle]="{'width':isAboutPassed == true ? '100%':'0%'}">
                        </div>
                    </div>
                </div>
                <div style="display:inline-block;width:33.3%;margin-left:2px;">
                    <div class="km-progress-bar-parent" style="cursor: pointer;">
                        <div class="km-progress-bar-child" [ngStyle]="{'width':isHolderPassed == true ? '100%':'0%'}">
                        </div>
                    </div>
                </div>
                <div style="display:inline-block;width:33.3%;margin-left:2px;">
                    <div class="km-progress-bar-parent" style="cursor: pointer;">
                        <div class="km-progress-bar-child" [ngStyle]="{'width':isPasswordPassed == true ? '100%':'0%'}">
                        </div>
                    </div>
                </div>
            </div>

            <!-- basic info -->
            <div [ngStyle]="{'display':currentView == 'basic' ? 'block' : 'none'}" style="margin-top:25px;">

                <!-- legal name -->
                <div class="km-form-item-header-container">
                    <div class="field-error-div" [ngStyle]="{'display':legalNameError ? 'inline-block':'none'}">
                    </div>
                    <span class="km-form-item-title-span">Legal name</span>
                </div>

                <div style="display: flex;">
                    <div style="display: inline-block;width:50%;margin-right:4px;">
                        <input class="km-input" maxlength="100" (keyup)="checkBasicInfoChange()"
                            placeholder="First name" type="text" #firstnameInput name="firstname">
                    </div>
                    <div style="display: inline-block;width:50%;margin-left:4px;">
                        <input class="km-input" maxlength="100" (keyup)="checkBasicInfoChange()" placeholder="Last name"
                            type="text" #lastnameInput name="lastname">
                    </div>
                </div>

                <!-- display name -->
                <div class="km-form-item-container" style="margin-top:20px;">

                    <div class="km-form-item-header-container">
                        <div class="field-error-div">
                        </div>
                        <span class="km-form-item-title-span">Display name</span>
                        <span class="optional-field-span"> • </span>
                        <span class="optional-field-span km-tooltip" style="text-decoration: underline;cursor: pointer;"
                            data-tippy-content="Your legal name is only collected for verification purposes, and by default is not visible to anyone else on your team. Your display name is used as a replacement and will be visible to other users in the teams you're part of.<br/>By default we use your first & last name."
                            data-tippy-placement="top" data-tippy-delay="[100,200]">
                            What is this?</span>
                    </div>
                    <div style="width:100%;">
                        <input class="km-input" maxlength="100" type="text" (keyup)="checkBasicInfoChange()"
                            #displayNameInput name="name">
                    </div>

                </div>

                <!-- email -->
                <div class="km-form-item-container" style="margin-top:20px;">
                    <div class="km-form-item-header-container">
                        <div class="field-error-div" [ngStyle]="{'display':emailError ? 'inline-block':'none'}">
                        </div>
                        <span class="km-form-item-title-span">Email</span>
                    </div>
                    <div style="max-width:300px;">
                        <input class="km-input" maxlength="100" (keyup)="checkBasicInfoChange()" type="text" #emailInput
                            name="email">
                    </div>
                </div>

                <!-- phone -->
                <div class="km-form-item-container" style="margin-top:20px;">

                    <div>

                        <div class="km-form-item-header-container">
                            <div class="field-error-div" [ngStyle]="{'display':phoneError ? 'inline-block':'none'}">
                            </div>
                            <span class="km-form-item-title-span">Phone number</span>
                        </div>
                        <!-- phone number control. country selector + textbox -->
                        <div style="width: 100%;">
                            <app-phone-number-input-component #userPhoneComponent
                                componentElementId="sign_up_phone_drop"
                                (PhoneInputKeyDown)="handlePhoneNumberKeyin($event)">
                            </app-phone-number-input-component>
                        </div>
                    </div>

                </div>

                <div style="margin-top:30px;text-align: right;">
                    <div style="width:55px;" class="km-button km-button-positive"
                        (click)="navigateWindow('holder','basic')">
                        <span>Proceed</span>
                    </div>
                </div>

            </div>

            <!-- cardholder info -->
            <div [ngStyle]="{'display':currentView == 'holder' ? 'block' : 'none'}" style="margin-top:15px;">

                <!-- name on physical card -->
                <div class="km-form-item-container" style="margin-top:20px;">

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="km-form-item-header-container">
                                <span class="km-form-item-title-span">Name on physical card</span>
                            </div>
                            <div style="max-width:300px;">
                                <input class="km-input" maxlength="100" placeholder="{{firstName}} {{lastName}}"
                                    type="text" #physicalCardName name="name">
                            </div>
                        </div>
                    </div>

                </div>

                <div class="km-form-item-container" style="margin-top:20px;">
                    <div class="km-form-item-header-container">
                        <span class="km-form-item-title-span">Billing & Shipping address</span>
                    </div>
                    <app-address-module-component #billingAddressComponent [isCountryDropSelectable]="false"
                        SelectedCountryValue="US" AddressStateDropdownId="user_billing_address_state_dropdown"
                        AddressCountryDropdownId="user_setting_cardholder_billing_address">
                    </app-address-module-component>
                </div>


                <div style="margin-top:20px;display: flex;">
                    <div style="float: left;display: inline;width:50%;text-align: left;">
                        <div style="width:55px;float: left;display: inline" class="km-button km-button-negative"
                            (click)="navigateWindow('basic','holder')">
                            <span>Back</span>
                        </div>
                    </div>

                    <div style="float: right;display: inline;width:50%;text-align: right">
                        <div style="width:55px;" class="km-button km-button-positive"
                            (click)="navigateWindow('pass','holder')">
                            <span>Proceed</span>
                        </div>
                    </div>

                </div>


            </div>

            <!-- secury info -->
            <div [ngStyle]="{'display':currentView == 'pass' ? 'block' : 'none'}" style="margin-top:15px;">

                <!-- password -->
                <div class="km-form-item-container">
                    <div class="km-form-item-header-container">
                        <div class="field-error-div" [ngStyle]="{'display':passOneFieldError ? 'inline-block':'none'}">
                        </div>
                        <span class="km-form-item-title-span">Password</span>
                    </div>
                    <div style="width: 100%;">
                        <input class="km-input" (keyup)="checkBasicInfoChange()" type="text" #userPasswordInput
                            type="password" name="password">
                    </div>
                </div>

                <!-- re-enter password -->
                <div class="km-form-item-container">
                    <div class="km-form-item-header-container">
                        <div class="field-error-div" [ngStyle]="{'display':passTwoFieldError ? 'inline-block':'none'}">
                        </div>
                        <span class="km-form-item-title-span">Confirm password</span>
                    </div>
                    <div style="width: 100%;">
                        <input class="km-input" (keyup)="checkBasicInfoChange()" type="text"
                            #userConfirmNewPasswordInput type="password" name="password">
                    </div>

                </div>

                <div style="margin-top:30px;display: flex;">
                    <div style="float: left;display: inline;width:50%;text-align: left;">
                        <div [ngStyle]="{'display':createAccountButtonOneLoading == true ? 'none':'inline'}"
                            style="width:55px;float: left;display: inline" class="km-button km-button-negative"
                            (click)="navigateWindow('holder','pass')">
                            <span>Back</span>
                        </div>
                    </div>

                    <div style="float: right;display: inline;width:50%;text-align: right">
                        <div style="width:55px;" class="km-button km-button-positive" (click)="createAccount()"
                            [ngClass]="{'km-button-clicked':createAccountButtonOneLoading == true}">
                            <div class="km-btn-spinner"
                                [ngClass]="{'km-btn-spinner-show':createAccountButtonOneLoading == true}">
                            </div>
                            <span
                                [ngClass]="{'km-button-span-hide':createAccountButtonOneLoading == true}">Submit</span>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</div>