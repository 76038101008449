import { Component } from '@angular/core';
declare function openSupportWidget(): any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Kapmates';

openSupportWindow()
{
  openSupportWidget();
}

}
