<div>
    <app-notification-list-component></app-notification-list-component>

    <!-- <div (click)="openSupportWindow()" class="km-chat-activato-button">
        <div class="live-chat-svg-holder">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                <path
                    d="M10 0C4.47327 0 0 4.4725 0 10C0 11.754 0.457458 13.4671 1.32614 14.978L0.0376892 18.9792C-0.0521851 19.2581 0.0216675 19.5639 0.228882 19.7711C0.434113 19.9763 0.739136 20.0529 1.02081 19.9623L5.02197 18.6739C6.5329 19.5425 8.246 20 10 20C15.5267 20 20 15.5275 20 10C20 4.47327 15.5275 0 10 0ZM10 18.4375C8.41568 18.4375 6.87134 17.9955 5.53391 17.1591C5.34149 17.0389 5.10254 17.0064 4.88022 17.0779L1.99188 18.0081L2.92206 15.1198C2.99255 14.9007 2.9628 14.6614 2.84073 14.4661C2.00455 13.1287 1.5625 11.5843 1.5625 10C1.5625 5.3476 5.3476 1.5625 10 1.5625C14.6524 1.5625 18.4375 5.3476 18.4375 10C18.4375 14.6524 14.6524 18.4375 10 18.4375ZM10.9766 10C10.9766 10.5392 10.5394 10.9766 10 10.9766C9.4606 10.9766 9.02344 10.5392 9.02344 10C9.02344 9.4606 9.4606 9.02344 10 9.02344C10.5394 9.02344 10.9766 9.4606 10.9766 10ZM14.8828 10C14.8828 10.5392 14.4456 10.9766 13.9062 10.9766C13.3669 10.9766 12.9297 10.5392 12.9297 10C12.9297 9.4606 13.3669 9.02344 13.9062 9.02344C14.4456 9.02344 14.8828 9.4606 14.8828 10ZM7.07031 10C7.07031 10.5392 6.63315 10.9766 6.09375 10.9766C5.5545 10.9766 5.11719 10.5392 5.11719 10C5.11719 9.4606 5.5545 9.02344 6.09375 9.02344C6.63315 9.02344 7.07031 9.4606 7.07031 10Z"
                    fill="#02051d" />
            </svg>
        </div>
        <span>Support</span>
    </div> -->

    <router-outlet></router-outlet>
</div>