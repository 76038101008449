<div>
    <app-navbar></app-navbar>

    <div class="main-holder">

        <div style="text-align:left;max-width:600px;margin:auto;">

            <div [ngStyle]="{'opacity':isLoaded == true ? '1':'0'}" class="sec-header-holder">
                <span class="sec-header">Security</span>
                <br>
                <div style="margin-bottom: 20px;margin-top:20px;">
                    <span style="color: #fff;font-size:15px;font-weight:400;line-height:1.5;">
                        The safety of your data on Kapmates is our topmost priority, thus, we incorporate industry best
                        practices & techniques that ensure the safeguarding of your information.</span>
                </div>
            </div>


            <div class="security-main-window" [ngStyle]="{'opacity':isLoaded == true ? '1':'0'}"
                style="opacity:0;transition: opacity 0.25s ease-in-out;">

                <!-- encryption -->
                <div style="width:100%;">

                    <span style="color: #b6e8f9 ;font-size:18px;font-weight:400;">
                        Data Encryption
                    </span>
                    <div style="margin-top:10px;">
                        <span style="color: rgba(216, 216, 216, 0.637);font-size:15px;font-weight:400;line-height:1.5;">
                            In Transit
                        </span>

                        <div style="margin-top:1px;">
                            <span style="color: #fff;font-size:15px;font-weight:400;line-height:1.5;">All data transfers
                                between your browser and our servers are encrypted. Nothing is ever sent in raw
                                text.</span>
                        </div>

                    </div>

                    <div style="margin-top:15px;">
                        <span style="color: rgba(216, 216, 216, 0.637);font-size:15px;font-weight:400;line-height:1.5;">
                            At Rest
                        </span>

                        <div style="margin-top:1px;">
                            <span style="color: #fff;font-size:15px;font-weight:400;line-height:1.5;">All personally
                                identifiable information is encrypted upon storage. We incorporate application-level
                                encryption, on top of the default database-level encryption.
                            </span>
                        </div>

                    </div>

                </div>

                <div style="width:100%;margin-top:30px;">
                    <span style="color: #b6e8f9;font-size:18px;font-weight:400;">
                        Policies & Practices
                    </span>

                    <div style="margin-top:10px;">
                        <span style="color: rgba(216, 216, 216, 0.637);font-size:15px;font-weight:400;line-height:1.5;">
                            Encryption key rotation
                        </span>

                        <div style="margin-top:1px;">
                            <span style="color: #fff;font-size:15px;font-weight:400;line-height:1.5;">We frequently
                                rotate encryption keys.</span>
                        </div>

                    </div>

                    <div style="margin-top:10px;">
                        <span style="color: rgba(216, 216, 216, 0.637);font-size:15px;font-weight:400;line-height:1.5;">
                            Multi-factor authentication
                        </span>

                        <div style="margin-top:1px;">
                            <span style="color: #fff;font-size:15px;font-weight:400;line-height:1.5;">We implement 2FA
                                for account log ins.</span>
                        </div>

                    </div>

                    <div style="margin-top:10px;">
                        <span style="color: rgba(216, 216, 216, 0.637);font-size:15px;font-weight:400;line-height:1.5;">
                            Audit trails
                        </span>

                        <div style="margin-top:1px;">
                            <span style="color: #fff;font-size:15px;font-weight:400;line-height:1.5;">Every important
                                activity on Kapmates, like request reviews, balance top-ups, object creations, updates,
                                & deletions, user removals, and more, is logged and provided upon request. This can be
                                used to identify who did what and when.</span>
                        </div>
                    </div>

                    <div style="margin-top:10px;">
                        <span style="color: rgba(216, 216, 216, 0.637);font-size:15px;font-weight:400;line-height:1.5;">
                            Servers
                        </span>

                        <div style="margin-top:1px;">
                            <span style="color: #fff;font-size:15px;font-weight:400;line-height:1.5;">Application & data
                                is hosted on state-of-the-art servers, behind firewalls, that is frequently updated with
                                the latest security patches.</span>
                        </div>

                    </div>



                </div>

                <br> <br> <br>

            </div>


        </div>

        <div class="side-des-ver" style="right:-20px!important;top:10%;position: absolute;">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="492" viewBox="0 0 34 492" fill="none">
                <rect width="34" height="322" rx="17" fill="#FFD384" />
                <circle cx="17" cy="367" r="17" fill="#9DE4FF" />
                <circle cx="17" cy="421" r="17" fill="#fff" />
                <circle cx="17" cy="475" r="17" fill="#B178F6" />
            </svg>
        </div>

    </div>


</div>