<div class="km-address-component-main-holder">

    <div class="row" style="margin-bottom:4px!important;">
        <div class="col-md-6 col-lg-6 col-sm-12">

            <div class="km-form-item-container" style="margin-bottom:7px!important;">
                <div class="km-form-item-header-container">
                    <div class="field-error-div field-error-div-fadeIn"></div>
                    <span class="km-address-form-item-title-span">Country</span>
                </div>
                <!-- dropdown to select from a list of industries -->
                <div style="max-width: 270px;">
                    <app-country-dropdown-component [IsSelectable]="isCountryDropSelectable"
                        [componentElementId]="AddressCountryDropdownId" #addressCountryComponent
                        (selectedItem)="dropDownTriggerHandler($event)" [IncomingSelectedValue]="SelectedCountryValue">
                    </app-country-dropdown-component>
                </div>

            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6 col-lg-6 col-sm-12">
            <div class="km-form-item-container" style="margin-bottom:7px!important;">
                <div class="km-form-item-header-container">
                    <div class="field-error-div"
                        [ngStyle]="{'display':addressInputValidationObject.LineOne ? 'inline-block':'none'}"></div>
                    <span class="km-address-form-item-title-span">Line 1</span>
                </div>
                <div style="max-width: 270px;">
                    <input class="km-input" name="address-line-1" maxlength="100" placeholder="12 Skillful Homes Ave."
                        type="text" #addressLineOne (keyup)="inputKeyUpHandler()"
                        [attr.disabled]="isAddressDisabled ? '' : null">
                </div>

            </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12">
            <div class="km-form-item-container" style="margin-bottom:7px!important;">
                <div class="km-form-item-header-container">
                    <span class="km-address-form-item-title-span">Line 2</span> 
                </div>
                <div style="max-width: 270px;">
                    <input class="km-input" maxlength="70" name="line2" placeholder="Apt 1" type="text"
                        (keyup)="inputKeyUpHandler()" #addressLineTwo [attr.disabled]="isAddressDisabled ? '' : null">
                </div>

            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6 col-lg-6 col-sm-12">
            <div class="km-form-item-container" style="margin-bottom:7px!important;">
                <div class="km-form-item-header-container">
                    <div class="field-error-div"
                        [ngStyle]="{'display':addressInputValidationObject.City ? 'inline-block':'none'}"></div>
                    <span class="km-address-form-item-title-span">City</span>
                </div>
                <div style="max-width: 270px;">
                    <input class="km-input" maxlength="100" name="address-city" placeholder="Denver" type="text"
                        #addressCity (keyup)="inputKeyUpHandler()" [attr.disabled]="isAddressDisabled ? '' : null">
                </div>

            </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12">
            <div class="km-form-item-container" style="margin-bottom:7px!important;">
                <div class="km-form-item-header-container">
                    <div class="field-error-div field-error-div-fadeIn"></div>
                    <span class="km-address-form-item-title-span">State</span>
                </div>
                <!-- dropdown to select from a list of industries -->
                <div style="max-width: 270px;">
                    <app-standard-searchable-dropdown [componentElementId]="AddressStateDropdownId"
                        #addressStateComponent [sendBackSelection]="true"
                        (selectedItem)="dropDownTriggerHandler($event)" [IsSelectable]="isStateSelectable">
                    </app-standard-searchable-dropdown>
                </div>

            </div>
        </div>
    </div>

    <div class="row" style="margin-top:2px!important;">
        <div class="col-md-6 col-lg-6 col-sm-12">
            <div class="km-form-item-container" style="margin-bottom:7px!important;">
                <div class="km-form-item-header-container">
                    <div class="field-error-div"
                        [ngStyle]="{'display':addressInputValidationObject.Zip ? 'inline-block':'none'}"></div>
                    <span class="km-address-form-item-title-span">Zip</span>
                </div>
                <div style="max-width: 270px;">
                    <input class="km-input" maxlength="5" name="address-zip" placeholder="80110" type="text" #addressZip
                        (keyup)="inputKeyUpHandler()" [attr.disabled]="isAddressDisabled ? '' : null">
                </div>

            </div>
        </div>

    </div>

</div>