import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PasswordResetComponent } from './AuthenticationComponents/password-reset/password-reset.component';
import { SigninComponent } from './AuthenticationComponents/signin/signin.component';
import { SignupComponent } from './AuthenticationComponents/signup/signup.component';
import { MainPageComponent } from './HomepageComponents/main-page/main-page.component';
import { PricingPageComponent } from './HomepageComponents/pricing-page/pricing-page.component';
import { SecurityComponent } from './HomepageComponents/security/security.component';
import { VisionComponent } from './HomepageComponents/vision/vision.component';

const routes: Routes = [

  { path: '', component: MainPageComponent },
  // { path: 'pricing', component: PricingPageComponent },
  // { path: 'plans', redirectTo: 'pricing' },
  { path: 'signin', component: SigninComponent },
  { path: 'login', redirectTo: 'signin'  },
  { path: 'signup', component: SignupComponent },
  { path: 'security', component: SecurityComponent },
  { path: 'privacy', redirectTo: 'security' },
  // { path: 'vision', component: VisionComponent },
  // { path: 'roadmap', redirectTo: 'vision' },
  // { path: 'vision', component: VisionComponent },
  { path: 'reset-password', component: PasswordResetComponent },
  { path: '**', redirectTo: '' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
