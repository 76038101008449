<div class="km-request-access-main-window"
    [ngClass]="{'km-request-access-main-window-open':isWindowOpen == true,'km-request-access-main-window-close':isWindowOpen == false}">

    <div style="display: flex;" class="request-window-header">
        <div style="margin-top:5px;display: inline;float: left;width:calc(100% - 50px);">
            <span class="km-sign-in-text" style="font-size:13px;font-weight:500;">Tell us about you</span>
        </div>

        <div style="position: relative;display: inline;float: left;width:50px">
            <div style="position:absolute;right:1px;top:0.5px;">
                <div (click)="toggleAccessRequest(false)" class="nav-menu-sm-toggler-close"
                    style="height:27px;width:27px;">
                    <div style="position:absolute;top:4.1px; left:8.7px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 25 25" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.774279 0.774271C0.160405 1.38815 0.160404 2.38343 0.774279 2.99731L10.0908 12.3139L0.774285 21.6304C0.16041 22.2443 0.16041 23.2396 0.774285 23.8535C1.38816 24.4673 2.38345 24.4673 2.99732 23.8535L12.3139 14.5369L21.6304 23.8535C22.2443 24.4673 23.2396 24.4673 23.8535 23.8535C24.4673 23.2396 24.4673 22.2443 23.8535 21.6304L14.5369 12.3139L23.8535 2.99731C24.4673 2.38343 24.4673 1.38815 23.8535 0.774273C23.2396 0.160399 22.2443 0.160398 21.6304 0.774273L12.3139 10.0908L2.99731 0.774271C2.38344 0.160397 1.38815 0.160397 0.774279 0.774271Z"
                                fill="#00002C" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <hr style="border-width: 0.1px;margin-top: 12px;border-color:rgb(240, 240, 240,0.10);">

    <div style="height:calc(100% - 50px);overflow-y: auto;padding: 10px;overflow-x: hidden;max-width:400px;">
        <!-- name -->
        <div class="km-form-item-container" style="margin-top:5px;">
            <div class="km-form-item-header-container">
                <div class="field-error-div" [ngStyle]="{'display':nameError ? 'inline-block':'none'}">
                </div>
                <span class="km-form-item-title-span">Name</span>
            </div>
            <div style="max-width:500px;">
                <input class="km-input" maxlength="100" (keyup)="checkBasicInfoChange()" type="text" #requesterName
                    name="name">
            </div>
        </div>

        <!-- email -->
        <div class="km-form-item-container" style="margin-top:20px;">
            <div class="km-form-item-header-container">
                <div class="field-error-div" [ngStyle]="{'display':emailError ? 'inline-block':'none'}">
                </div>
                <span class="km-form-item-title-span">Email</span>
            </div>
            <div style="max-width:500px;">
                <input class="km-input" maxlength="100" (keyup)="checkBasicInfoChange()" type="text" #requesterEmail
                    name="email">
            </div>
        </div>

        <!-- company name -->
        <div class="km-form-item-container" style="margin-top:20px;">
            <div class="km-form-item-header-container">
                <div class="field-error-div" [ngStyle]="{'display':companyNameError ? 'inline-block':'none'}">
                </div>
                <span class="km-form-item-title-span">Company name</span>
            </div>
            <div style="max-width:500px;">
                <input class="km-input" maxlength="100" (keyup)="checkBasicInfoChange()" type="text" #requesterCompanyName
                    name="company">
            </div>
        </div>

        <!-- Role in company -->
        <div class="km-form-item-container" style="margin-top:20px;">
            <div class="km-form-item-header-container">
                <div class="field-error-div" [ngStyle]="{'display':companyRoleError ? 'inline-block':'none'}">
                </div>
                <span class="km-form-item-title-span">Role in company</span>
            </div>
            <div style="max-width:500px;">
                <input class="km-input" maxlength="100" placeholder="Founder & CEO" (keyup)="checkBasicInfoChange()"
                    type="text" #requesterRole name="role">
            </div>
        </div>

        <!-- company size -->
        <div class="km-form-item-container">
            <div class="km-form-item-header-container">
                <span class="km-form-item-title-span">Company size</span>
            </div>
            <div style="max-width:100%;">
                <app-standard-searchable-dropdown isSearchable="false" [sentInData]="teamsizeOptions"
                    [isDataSentIn]="true" #companySizeComp componentElementId="request_access_company_size">
                </app-standard-searchable-dropdown>
            </div>
        </div>

        <!-- monthly spend -->
        <div class="km-form-item-container">
            <div class="km-form-item-header-container">
                <span class="km-form-item-title-span">Estimated monthly spend</span>
            </div>
            <div style="max-width:100%;">
                <app-standard-searchable-dropdown isSearchable="false" [sentInData]="companyMonthlySpend"
                    [isDataSentIn]="true" #companyMonthlySpendComp componentElementId="request_access_company_spend">
                </app-standard-searchable-dropdown>
            </div>
        </div>

        <div style="float: right;display: inline;width:50%;text-align: right;margin-bottom:10px;margin-top:10px;">
            <div style="width:55px;" class="km-button km-button-positive" (click)="sendAccessRequest()"
                [ngClass]="{'km-button-clicked':requestButtonOneLoading == true}">
                <div class="km-btn-spinner" [ngClass]="{'km-btn-spinner-show':requestButtonOneLoading == true}">
                </div>
                <span [ngClass]="{'km-button-span-hide':requestButtonOneLoading == true}">Submit</span>
            </div>

        </div>

    </div>






</div>