<div>
    <app-navbar></app-navbar>

    <div class="km-main-body-one">

        <div [ngStyle]="{'opacity':isLoaded == true ? '1':'0'}" class="km-main-container km-main-f-b"
            style="display: block;position: relative;opacity: 0;transition: opacity 0.25s ease-in-out;">

            <div class="km-main-tagline-container">
                <span class="tagline-span">Gain </span>
                <span class="km-text-gradient tagline-span">Control & Visibility</span>
                <span class="tagline-span"> over your Company Spend.</span>
                <div style="margin-top:20px;">
                    <span class="sub-text-span">Issue Physical & Virtual Cards. Distribute Spending across your Company.
                        Granularly Control & Track everything.</span>
                </div>
                <div style="margin-top:8px;">
                    <span class="fdic-insured-span">Kapmates accounts are FDIC insured (up to $250,000) through our
                        partner bank.</span>
                </div>

                <div style="margin-top:35px;">

                    <div (click)="toggleAccessRequest()" class="btn-request-access">
                        <span>Request Access</span>
                    </div>

                    <div class="btn-watch-tour" (click)="toggleWatchVid(true)">
                        <span>Watch Tour <svg style="top:3px;position: relative;left:3px"
                                xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20"
                                fill="none">
                                <path
                                    d="M9.99998 0C4.47715 0 0 4.47715 0 9.99998C0 15.5228 4.47715 20 9.99998 20C15.5228 20 20 15.5228 20 9.99998C19.9941 4.47961 15.5204 0.00590122 9.99998 0ZM9.99998 18.5714C5.26611 18.5714 1.42856 14.7338 1.42856 9.99998C1.42856 5.26611 5.26611 1.42856 9.99998 1.42856C14.7338 1.42856 18.5714 5.26611 18.5714 9.99998C18.5663 14.7318 14.7318 18.5663 9.99998 18.5714Z"
                                    fill="#a852fd" />
                                <path
                                    d="M14.2114 9.6813C14.1421 9.54243 14.0296 9.42984 13.8907 9.36062L8.17642 6.50347C7.82352 6.32714 7.39452 6.47023 7.21816 6.82314C7.16856 6.92237 7.14278 7.03181 7.14282 7.14276V12.8571C7.14265 13.2516 7.46228 13.5715 7.85679 13.5717C7.96774 13.5717 8.07718 13.546 8.17642 13.4964L13.8907 10.6392C14.2438 10.4633 14.3874 10.0344 14.2114 9.6813ZM8.57142 11.7014V8.29848L11.9743 9.99992L8.57142 11.7014Z"
                                    fill="#a852fd" />
                            </svg></span>

                    </div>

                </div>

            </div>

            <div class="km-main-video-preview">

                <!-- <div class="preview-image-play-btn" (click)="toggleWatchVid(true)">
                    <div style="border-radius:100px;min-width:30px;" class="btn-watch-tour">
                        <span><svg style="top:3px;position: relative;" xmlns="http://www.w3.org/2000/svg" width="25"
                                height="25" viewBox="0 0 20 20" fill="none">
                                <path
                                    d="M9.99998 0C4.47715 0 0 4.47715 0 9.99998C0 15.5228 4.47715 20 9.99998 20C15.5228 20 20 15.5228 20 9.99998C19.9941 4.47961 15.5204 0.00590122 9.99998 0ZM9.99998 18.5714C5.26611 18.5714 1.42856 14.7338 1.42856 9.99998C1.42856 5.26611 5.26611 1.42856 9.99998 1.42856C14.7338 1.42856 18.5714 5.26611 18.5714 9.99998C18.5663 14.7318 14.7318 18.5663 9.99998 18.5714Z"
                                    fill="#a852fd" />
                                <path
                                    d="M14.2114 9.6813C14.1421 9.54243 14.0296 9.42984 13.8907 9.36062L8.17642 6.50347C7.82352 6.32714 7.39452 6.47023 7.21816 6.82314C7.16856 6.92237 7.14278 7.03181 7.14282 7.14276V12.8571C7.14265 13.2516 7.46228 13.5715 7.85679 13.5717C7.96774 13.5717 8.07718 13.546 8.17642 13.4964L13.8907 10.6392C14.2438 10.4633 14.3874 10.0344 14.2114 9.6813ZM8.57142 11.7014V8.29848L11.9743 9.99992L8.57142 11.7014Z"
                                    fill="#a852fd" />
                            </svg></span>

                    </div>
                </div> -->


                <img class="preview-image" (click)="toggleWatchVid(true)"
                    src="https://kapmatesfiles.sfo3.cdn.digitaloceanspaces.com/web_assets/homepageDashboardPreview.PNG"
                    alt="dashboard">

            </div>

            <!-- <div class="glowball" style="top:10px;right:-150px;">            
            </div> -->

        </div>

        <!-- cards -->
        <div class="card-desc-holder">
            <div class="km-card-header-m-hlder">
                <h1 style="color: #fff;font-size:60px;"><span class="km-text-gradient tagline-span">Unlimited
                        Cards</span> <span style="font-weight:500;">. For Everyone.
                    </span></h1>
            </div>

            <div class="card-desc-transp-container">

                <div class="row">

                    <div class="col-md-4 col-lg-4">

                        <div style="padding:20px;">

                            <div>
                                <div>
                                    <span style="color:#dfeffb;line-height:1.7;font-size:20px;">Physical Cards</span>
                                    <br>
                                    <span style="color:#fff;font-size:13.5px;">We automatically mail a physical card to
                                        every person you add to your company on Kapmates. This card can be used to spend
                                        from anywhere they permitted in your company.</span>

                                </div>

                                <div style="margin-top:50px;">
                                    <span style="color:#dfeffb;line-height:1.7;font-size:20px;">Unlimited Virtual
                                        Cards</span>
                                    <br>
                                    <span style="color:#fff;font-size:13.5px;">Issue additional &
                                        unlimited cards for more specific spends across the different teams, projects
                                        and budgets
                                        in your company.</span>
                                    <br>
                                    <div style="margin-top:9px">
                                        <span style="color:rgb(219, 219, 219);font-size:13px;">Teams
                                            on Kapmates issue different virtual cards for their SaaS
                                            subscriptions.</span>
                                    </div>
                                </div>

                                <div style="margin-top:50px;">
                                    <span style="color:#dfeffb;line-height:1.7;font-size:20px;">Unmatched
                                        Spending Controls</span>
                                    <br>
                                    <span style="color:#fff;font-size:13.5px;">Prevent unwanted
                                        charges with strict spending controls & policies - regulate where, when, and how
                                        cards are used.</span>

                                </div>

                            </div>

                        </div>


                    </div>

                    <div class="col-md-8 col-lg-8 card-image-holder" style="text-align: center;">

                        <img draggable="false" style="width:100%;max-width:700px;"
                            src="https://kapmatesfiles.sfo3.cdn.digitaloceanspaces.com/web_assets/cardillustrationV7.svg"
                            alt="cards">

                    </div>

                </div>

                <div class="glowball" style="top:70%;left:-150px;">
                </div>

                <div class="side-des-ver" style="position: absolute; right:-20px; top: -20%; z-index: -1;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="492" viewBox="0 0 34 492" fill="none">
                        <rect width="34" height="322" rx="17" fill="#fff" />
                        <circle cx="17" cy="367" r="17" fill="#FFD384" />
                        <circle cx="17" cy="421" r="17" fill="#B178F6" />
                        <circle cx="17" cy="475" r="17" fill="#9DE4FF" />
                    </svg>
                </div>

            </div>


        </div>

        <!-- flexible -->
        <div class="categorization-desc-holder">

            <div style="text-align: center;max-width:1300px;margin:auto;">

                <div>

                    <div class="row">

                        <div style="margin-top:15px;padding-top: 5px;"
                            class="col-md-5 col-lg-5 cate-cont-hldr km-cate-op-hldr">

                            <div style="text-align: left;">
                                <h1 style="color:#fff;font-size:50px;margin-top:0;"><span style="font-size:50px;"
                                        class="km-text-gradient tagline-span">Flexible.<br></span>
                                    <span style="font-weight:500;"> So make it yours.</span>
                                </h1>

                            </div>

                            <div style="text-align: left;margin-top:20px;">

                                <div style="margin-top:20px">
                                    <h3>
                                        <span style="color: #fff;font-size:14.5px;font-weight:400;">
                                            Use and combine Groups, Projects & Budgets to distribute and control
                                            spending at scale, in your
                                            company. These are separate spaces in your company that can have different
                                            users & cards, admins, request & approval workflows, policies, and reports.
                                        </span>
                                    </h3>
                                    <div style="margin-top:0px">
                                        <span style="color:rgb(219, 219, 219);font-size:13px;">Teams on Kapmates use
                                            Groups to bring people together for the different departments in their
                                            company. Some use Projects to track every expense for the projects they work
                                            on. Best of all, they can interconnect them - like linking multiple
                                            departments/groups to one project and so much more.</span>
                                    </div>
                                </div>

                                <div class="cat-img-sm-scr" style="margin-top:15px;">

                                    <img draggable="false" class="km-object-image"
                                        src="https://kapmatesfiles.sfo3.cdn.digitaloceanspaces.com/web_assets/FlexibleIllustration13.svg"
                                        alt="flex">
                                </div>

                            </div>

                        </div>

                        <div class="col-md-7 col-lg-7 cate-options-img-hldr">

                            <img draggable="false" class="km-object-image" style="max-width:700px;"
                                src="https://kapmatesfiles.sfo3.cdn.digitaloceanspaces.com/web_assets/FlexibleIllustration13.svg"
                                alt="flex">
                        </div>

                    </div>

                </div>

                <div class="side-des-ver" style="position: absolute; left:-20px; bottom: -20%; z-index: -1;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="492" viewBox="0 0 34 492" fill="none">
                        <rect width="34" height="322" rx="17" fill="#FFD384" />
                        <circle cx="17" cy="367" r="17" fill="#B178F6" />
                        <circle cx="17" cy="421" r="17" fill="#9DE4FF" />
                        <circle cx="17" cy="475" r="17" fill="#fff" />
                    </svg>
                </div>

            </div>


        </div>


        <!-- policies and rules -->
        <div class="policies-rules-desc-holder">

            <div style="text-align: center;max-width:1300px;margin:auto;">

                <div class="km-policy-header-hldr">
                    <h1 style="color: #fff;font-size:60px;">
                        <span style="font-weight:500;">Your Money. </span>
                        <span class="km-text-gradient tagline-span">Your Rules.</span>
                    </h1>
                </div>

                <div class="km-pol-left-cont">

                    <div class="row">

                        <div class="col-md-5 col-lg-5 km-policy-cont-right" style="padding-top:0px;">

                            <img draggable="false" style="max-width:370px !important;" class="km-object-image"
                                src="https://kapmatesfiles.sfo3.cdn.digitaloceanspaces.com/web_assets/policiyupdated2.svg"
                                alt="policy builder">

                        </div>


                        <div class="col-md-7 col-lg-7 km-policy-cont-left">


                            <div style="margin-bottom:40px;">
                                <span style="color: #fff;font-size:16px;font-weight:400;line-height:1.7;">
                                    Kapmates gives you the ability to granularly control every aspect of your
                                    company's spending
                                    process - at scale.
                                </span>
                            </div>

                            <span style="font-weight:500;color: #dfeffb;font-size:14.5px;">Spending Policies</span>

                            <div style="margin-top: 8px;">
                                <span style="color:#fff;line-height:1.7;font-size:14.5px;">
                                    Use spending policies to approve or decline payment
                                    authorizations. They can be attached to cards, groups, projects,
                                    budgets, and even
                                    the
                                    company itself. Spending policies can help:
                                </span>
                                <div style="margin-top:3px;">
                                    <span style="color:#fff;line-height:1.7;font-size:13.5px;">
                                        <span style="font-size:18px;color:#dfeffb">•</span> Prevent unwanted
                                        charges.</span>
                                </div>

                                <div style="margin-top:5px;">
                                    <span style="color:#fff;line-height:1.7;font-size:13.5px;">
                                        <span style="font-size:18px;color:#dfeffb">•</span> Control spending at
                                        scale.</span>
                                </div>

                                <div style="margin-top:5px;">
                                    <span style="color:#fff;line-height:1.7;font-size:13.5px;">
                                        <span style="font-size:18px;color:#dfeffb">•</span> Reduce
                                        or eliminate the need for request & approval workflows.</span>
                                </div>

                            </div>

                            <div class="pol-img-sm-scr">
                                <img draggable="false" style="width: 100%;border-radius:7px;"
                                    src="https://kapmatesfiles.sfo3.cdn.digitaloceanspaces.com/web_assets/policiyupdatedT.svg"
                                    alt="policy builder">
                            </div>

                            <div style="margin-top:30px;">
                                <span style="font-weight:500;color: #dfeffb;font-size:14.5px;">Receipt & Memo
                                    Policy</span>

                                <div style="margin-top: 8px;">

                                    <span style="color:#fff;line-height:1.7;font-size:14.5px;">
                                        Set a policy for your company's transactions receipt and memo requirements,
                                        and we'll
                                        take care of the rest.
                                    </span>

                                </div>

                            </div>

                            <div style="margin-top:30px;">
                                <span style="font-weight:500;color: #dfeffb;font-size:14.5px;">User Roles &
                                    Permission
                                </span>

                                <div style="margin-top: 8px;">

                                    <span style="color:#fff;line-height:1.7;font-size:14.5px;">
                                        Use customizable user role permissions to control what users can see & do in
                                        your company.
                                    </span>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="glowball"
                    style="top:5%; right: -133px; min-height: 250px; min-width: 250px; height: 250px; width: 250px;">
                </div>



            </div>

        </div>

        <!-- review flow -->
        <div class="policies-rules-desc-holder">

            <div style="text-align: center;max-width:1300px;margin:auto;">

                <div class="km-policy-header-hldr">
                    <h1 style="color: #fff;font-size:60px;">
                        <span class="km-text-gradient tagline-span">Request & Approval Workflows</span>
                        <span style="font-weight:500;"></span>
                    </h1>
                </div>

                <div class="km-cate-tag-hldr">
                    <h3>
                        <span style="color: #fff;font-size:16px;font-weight:400;">
                            Requests are routed to authorized users for review. From budget requests in the company to
                            new cards & limit requests across the different groups, projects & budgets in your company.
                        </span>
                    </h3>

                </div>

                <div class="km-exp-vid-holder">

                    <video class="km-project-image-shadow" style="width:100%;border-radius:10px;" autoplay muted loop
                        playsinline
                        src="https://kapmatesfiles.sfo3.cdn.digitaloceanspaces.com/web_assets/requestReviewsNew.mp4"></video>
                </div>

                <div class="glowball"
                    style="top:20%;right:50px;min-height:200px;min-width:200px;height:200px;width:200px;">
                </div>


            </div>

        </div>

        <!-- Reports -->
        <div class="policies-rules-desc-holder reports-sm-scr">

            <div style="text-align: center;max-width:1300px;margin:auto;">

                <div class="row">

                    <div style="margin-top:15px;padding-top: 5px;"
                        class="col-md-5 col-lg-5 cate-cont-hldr km-cate-op-hldr">

                        <div style="text-align: left;">
                            <h1 style="color: #fff;font-size:50px;margin-top:0;">
                                <span style="font-weight:500;">Realtime </span>
                                <span class="km-text-gradient tagline-span" style="font-size:50px;">Reports &
                                    Analytics</span>
                            </h1>
                        </div>

                        <div style="text-align: left;margin-top:15px;">
                            <h3>
                                <span style="color: #fff;font-size:14.5px;font-weight:400;">
                                    Kapmates organizes your spending data and gives you the ability to zoom in & out of
                                    it seamlessly.
                                </span>

                            </h3>

                            <div style="margin-top:30px">
                                <h3>
                                    <span style="color: #fff;font-size:14.5px;font-weight:400;">
                                        From company-wide analytics to reports across the different groups, projects &
                                        budgets in your company, you can see where every penny goes, as they happen in
                                        real-time.
                                    </span>
                                </h3>
                            </div>

                        </div>

                    </div>

                    <div class="col-md-7 col-lg-7 analytics-img-cls" style="padding:15px">

                        <img draggable="false" style="max-width:640px;" class="km-object-image"
                            src="https://kapmatesfiles.sfo3.cdn.digitaloceanspaces.com/web_assets/analyticsillustration3.svg"
                            alt="flex">
                    </div>

                </div>

                <!-- ++++++++++++++++++++++++++++++++++++++++ -->

                <div class="side-des-ver" style="position: absolute; right: -20px; top: 40%; z-index: -1;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="492" viewBox="0 0 34 492" fill="none">
                        <rect width="34" height="322" rx="17" fill="#9DE4FF" />
                        <circle cx="17" cy="367" r="17" fill="#B178F6" />
                        <circle cx="17" cy="421" r="17" fill="#FFD384" />
                        <circle cx="17" cy="475" r="17" fill="#f5f5f5" />
                    </svg>
                </div>

            </div>

        </div>

        <!-- mate -->
        <div class="policies-rules-desc-holder">

            <div style="text-align: center;max-width:1300px;margin:auto;">

                <div class="km-policy-header-hldr">
                    <h1 style="color: #fff;font-size:60px;">
                        <span style="font-weight:500;">Meet</span>
                        <span class="km-text-gradient tagline-span"> Mate</span>
                    </h1>
                </div>

                <div class="km-cate-tag-hldr">
                    <h3>
                        <span style="color: #fff;font-size:16px;font-weight:400;">
                            Mate is your company's Personal Financial Assistant. It can help you & your team easily explore your
                            spending data, and perform certain actions on your account, using natural language - just like
                            talking to a friend.
                        </span>
                    </h3>

                </div>

                <div class="km-exp-vid-holder">

                    <video class="km-budget-image-shadow" style="width:100%;border-radius:10px;" autoplay muted loop
                        playsinline
                        src="https://kapmatesfiles.sfo3.cdn.digitaloceanspaces.com/web_assets/mateDemoHp.mp4"></video>
                </div>

                <div class="glowball"
                    style="top:80%;left:105px;min-height:140px;min-width:140px;height:140px;width:140px;">
                </div>

            </div>

        </div>

        <!-- sms - mate -->
        <div class="policies-rules-desc-holder">

            <div style="max-width:1000px;margin:auto;">


                <div class="row">

                    <div style="margin-top:15px;padding-top: 5px;"
                        class="col-md-6 col-lg-6 cate-cont-hldr km-cate-op-hldr">

                        <div style="text-align: left;">
                            <h1 style="color: #fff;font-size:50px;margin-top:0;">

                                <span style="font-weight:500;">Do more.<br></span>
                                <span class="km-text-gradient tagline-span" style="font-size:50px;"> On the go.</span>

                            </h1>
                        </div>

                        <div style="text-align: left;margin-top: 15px;">

                            <span style="color: #fff;font-size:14.5px;font-weight:400;">
                                While we don't have a mobile app yet, we give you the ability to use Kapmates on the
                                go, through Mate & SMS.
                                From your smartphone, you can:
                            </span>


                            <div style="margin-top:18px;">
                                <span style="color:#fff;line-height:1.7;font-size:13.5px;">
                                    <span style="font-size:18px;color:#dfeffb">•</span> Upload transaction
                                    receipts - with multi-receipt matching capabilities</span>
                            </div>

                            <div style="margin-top:5px;">
                                <span style="color:#fff;line-height:1.7;font-size:13.5px;">
                                    <span style="font-size:18px;color:#dfeffb">•</span> Activate, Deactivate, & Cancel
                                    your cards</span>
                            </div>

                            <div style="margin-top:5px;">
                                <span style="color:#fff;line-height:1.7;font-size:13.5px;">
                                    <span style="font-size:18px;color:#dfeffb">•</span> Access some spending data &
                                    balances</span>
                            </div>

                        </div>

                    </div>

                    <div class="col-md-6 col-lg-6">

                        <div class="km-mate-sms-vid-hld">
                            <video class="km-group-image-shadow km-mate-sms-vid-hld-sm-scr"
                                style="width:100%;border-radius:10px;margin: auto;" autoplay muted loop playsinline
                                src="https://kapmatesfiles.sfo3.cdn.digitaloceanspaces.com/web_assets/mateSmsNew.mp4"></video>

                        </div>


                    </div>


                </div>


                <div class="side-des-ver" style="position: absolute; left:-20px; bottom:1%; z-index: -1;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="492" viewBox="0 0 34 492" fill="none">
                        <rect width="34" height="322" rx="17" fill="#B178F6" />
                        <circle cx="17" cy="367" r="17" fill="#FFD384" />
                        <circle cx="17" cy="421" r="17" fill="#fff" />
                        <circle cx="17" cy="475" r="17" fill="#9DE4FF" />
                    </svg>
                </div>


            </div>

        </div>


        <div class="policies-rules-desc-holder" style="margin-bottom:30px;margin-top:230px;">

            <div
                style="max-width:1000px;margin:auto;background-color:rgba(66, 66, 66, 0);padding:25px;border-radius:10px;margin-bottom:50px;">

                <div style="text-align:center;">
                    <div>
                        <span style="font-size:23px!important;" class="km-text-gradient tagline-span">Reimagine</span>
                        <span style="font-weight:400;color: #fff;font-size:23px;"> your
                            spend management today.
                        </span>
                    </div>

                    <div style="margin-top:20px;">
                        <div (click)="toggleAccessRequest()" class="btn-request-access">
                            <span>Request Access</span>
                        </div>
                    </div>


                </div>
            </div>


            <div
                style="max-width:1000px;margin:auto;background-color:rgba(66, 66, 66, 0.281);padding:25px;border-radius:10px;margin-top:20px;">

                <div style="text-align: left;">
                    <span class="sub-text-span" style="font-size:13px;">©{{currentYear.getFullYear()}} Kapmates Inc.
                        <span style="margin-left:8px;">•</span> <span
                            style="margin-left:8px;color:rgb(129, 129, 129);">Phase 1</span></span>
                </div>



            </div>


        </div>

    </div>

    <div [ngClass]="{'km-modal-open':isWatchVid == true, 'km-modal-close':isWatchVid == false}"
        (click)="toggleWatchVid(false)">

        <div class="km-modal-container-medium">

            <div class="km-modal-i-container">
                <iframe *ngIf="isWatchVid" class='sproutvideo-player' src='https://www.youtube.com/embed/DE1bE2S-VTk'
                    style="width:100%;height:100%;" frameborder='0' allowfullscreen></iframe>

            </div>


        </div>

    </div>

    <app-request-access #requestAccessComp></app-request-access>

</div>