import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RequestAccessComponent } from 'src/app/SharedComponents/request-access/request-access.component';

@Component({
  selector: 'app-pricing-page',
  templateUrl: './pricing-page.component.html',
  styleUrls: ['./pricing-page.component.css']
})
export class PricingPageComponent implements OnInit {

  constructor(
    private titleService: Title,
  ) { }

  public isLoaded: boolean = false;
  ngOnInit(): void {
    this.titleService.setTitle("Kapmates • Pricing");
    setTimeout(() => {
      this.isLoaded = true;
    }, 2);
  }

  public calculatedPrice: number;
  @ViewChild('totalCardsCount') totalCardsCount: ElementRef;
  @ViewChild('employeesCount') employeesCount: ElementRef;
  pricingCalc() {

    let totalCards: number = 0;
    let totalEmployees: number = 0;
    if (this.totalCardsCount.nativeElement.value.length > 0) {
      totalCards = parseInt(this.totalCardsCount.nativeElement.value);
    }

    this.calculatedPrice = 0;

    if (this.employeesCount.nativeElement.value.length > 0) {
      totalEmployees = parseInt(this.employeesCount.nativeElement.value);
    }

    if (totalEmployees > 0) {

      if (totalEmployees > 3 || totalCards > 9) {
        this.calculatedPrice = totalEmployees * 7;
      }

    }

  }

  @ViewChild('requestAccessComp') requestAccessComp: RequestAccessComponent;
  toggleAccessRequest() {
    this.requestAccessComp.toggleAccessRequest(true);
  }


}
