import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EmailValidatorClass } from 'src/app/Services/GeneralClasses/email-validator-class.service';
import { ApiRequestHandlerService } from 'src/app/Services/GeneralServices/api-request-handler.service';
import { CookieHandlingServicesService } from 'src/app/Services/GeneralServices/cookie-handling-services.service';
import { NotificationListComponentComponent } from 'src/app/SharedComponents/notification-list-component/notification-list-component.component';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  constructor(
    private titleService: Title,
    private apiService: ApiRequestHandlerService,
    private cookieHandler: CookieHandlingServicesService,

  ) {

  }

  @ViewChild('userEmailInput') userEmailInput: ElementRef;
  @ViewChild('userPasswordInput') userPasswordInput: ElementRef;

  public notificationComponent: NotificationListComponentComponent = new NotificationListComponentComponent();

  public passResetButtonOneLoading: boolean = false;

  public emailFieldError: boolean = false;
  public passFieldError: boolean = false;
  public emailPassResetFieldError: boolean = false;
  public showSignin: boolean = false;
  public showSignInAnimated = false;


  ngOnInit(): void {

    this.titleService.setTitle("Kapmates • Sign In");

    //if user id and token cookies are available, then redirect them to in.kapmates.com
    if (this.cookieHandler.checkCookie('user') == true && this.cookieHandler.checkCookie('token') == true) {
      window.location.href = 'https://in.kapmates.com';
    }
    else {

      this.showSignin = true;
      setTimeout(() => {
        this.showSignInAnimated = true;
      }, 20);

    }

  }

  public currentView: string = 'signin'; // signin || resetpass || 2fa

  ngAfterViewInit() {
    setTimeout(() => {
      this.userEmailInput.nativeElement.focus();
    }, 0);
  }


  togglePassReset(view: string) {
    if (view == 'signin') {
      this.emailPassResetFieldError = false;
      this.userPasswordResetEmailInput.nativeElement.value = '';
    }
    else if (view == 'resetpass') {
      this.emailFieldError = false;
      this.passFieldError = false;
      this.userEmailInput.nativeElement.value = '';
      this.userPasswordInput.nativeElement.value = '';
    }
    this.currentView = view;
  }

  getStateText(): string {
    if (this.currentView == 'resetpass') {
      return "Reset your password";
    }
    else if (this.currentView == '2fa') {
      return "Confirm 2FA code sent via SMS";
    }
    else {
      return "Let's get you in!";
    }
  }

  public signIn2FAToken: string = null;

  public signInButtonOneLoading: boolean = false;
  sendLoginRequest() {
    let email: string = this.userEmailInput.nativeElement.value;
    let password: string = this.userPasswordInput.nativeElement.value;

    if (this.signInButtonOneLoading == false) {
      if (email.length > 0 && password.length > 0) {

        let emailValidator: EmailValidatorClass = new EmailValidatorClass();

        if (emailValidator.validate(email) == true) {

          this.signInButtonOneLoading = true;

          //send request
          this.apiService.signInCheck({ email: email, password: password, token: this.signIn2FAToken }).subscribe({

            next: data => {

              if (data.success == true) {

                setTimeout(() => {

                  this.signInButtonOneLoading = false;

                  this.signIn2FAToken = data.token;

                  this.currentView = "2fa";

                  if (this.isCodeResendClicked == true) {
                    this.notificationComponent.displayNotification("Code resent!", "pos");
                  }

                }, 500);

              }
              //success == false
              else {
                setTimeout(() => {

                  this.signInButtonOneLoading = false;
                  this.notificationComponent.displayNotification(data.message, 'neg');

                }, 500);
              }

            },

            error: () => {

              setTimeout(() => {

                this.signInButtonOneLoading = false;
                this.notificationComponent.displayNotification("Authentication failed!", 'neg');

              }, 200);

            }

          })


        }

        else {
          this.emailFieldError = true;
        }
      }
      else {

        if (email.length == 0) {
          this.emailFieldError = true;
        }

        if (password.length == 0) {
          this.passFieldError = true;
        }

      }
    }



  }


  inputKeyUpHandler() {
    if (this.userEmailInput.nativeElement.value.length > 0) {
      this.emailFieldError = false;
    }
    if (this.userPasswordInput.nativeElement.value.length > 0) {
      this.passFieldError = false;
    }
    if (this.userPasswordResetEmailInput.nativeElement.value.length > 0) {
      this.emailPassResetFieldError = false;
    }
  }

  @ViewChild('tfa1') tfa1: ElementRef;
  @ViewChild('tfa2') tfa2: ElementRef;
  @ViewChild('tfa3') tfa3: ElementRef;
  @ViewChild('tfa4') tfa4: ElementRef;
  @ViewChild('tfa5') tfa5: ElementRef;
  @ViewChild('tfa6') tfa6: ElementRef;

  public tFaButtonOneLoading: boolean = false;

  twoFaEntered(current: string) {

    document.getElementById('tfa' + current).focus();

    let tfa1 = this.tfa1.nativeElement.value;
    let tfa2 = this.tfa2.nativeElement.value;
    let tfa3 = this.tfa3.nativeElement.value;
    let tfa4 = this.tfa4.nativeElement.value;
    let tfa5 = this.tfa5.nativeElement.value;
    let tfa6 = this.tfa6.nativeElement.value;

    if (tfa1.length == 1 && tfa2.length == 1 && tfa3.length == 1 && tfa4.length == 1 && tfa5.length == 1 && tfa6.length == 1) {

      this.tFaButtonOneLoading = true;

      let code = tfa1 + tfa2 + tfa3 + tfa4 + tfa5 + tfa6

      this.apiService.verify2Fa(code, this.signIn2FAToken).subscribe({

        next: data => {

          if (data.success == true) {

            setTimeout(() => {

              this.tFaButtonOneLoading = false;

              this.cookieHandler.setCookie('user', data.user_id);
              this.cookieHandler.setCookie('token', data.token);

              if (data.company_id != null && data.company_id != "") {
                this.cookieHandler.setCookie('company', data.company_id);
              }

              window.location.href = 'https://in.kapmates.com';

            }, 500);

          }
          //success == false
          else {
            setTimeout(() => {

              this.tFaButtonOneLoading = false;
              this.notificationComponent.displayNotification(data.message, 'neg');

            }, 300);
          }

        },

        error: () => {

          this.tFaButtonOneLoading = false;
          // this.notificationComponent.displayNotification("An error occurred!", 'neg');
          this.notificationComponent.displayNotification("Authentication failed!", 'neg');


        }

      })

    }

  }

  @ViewChild('userPasswordResetEmailInput') userPasswordResetEmailInput: ElementRef;

  sendResetRequest() {

    let email = this.userPasswordResetEmailInput.nativeElement.value;
    if (email.length > 0) {

      let emailValidator: EmailValidatorClass = new EmailValidatorClass();

      if (emailValidator.validate(email) == true) {

        this.passResetButtonOneLoading = true;

        //send request
        this.apiService.resetPassword({ email: email }).subscribe({

          next: data => {

            if (data.success == true) {

              setTimeout(() => {

                this.passResetButtonOneLoading = false;

                this.userPasswordResetEmailInput.nativeElement.value = '';

                this.notificationComponent.displayNotification('Password reset email sent!', 'pos');

                this.currentView = "signin";

              }, 500);

            }
            //success == false
            else {
              setTimeout(() => {

                this.passResetButtonOneLoading = false;
                this.notificationComponent.displayNotification(data.message, 'neg');

              }, 500);
            }

          },

          error: () => {

            setTimeout(() => {

              this.passResetButtonOneLoading = false;
              //this.notificationComponent.displayNotification("An error occurred!", 'neg');
              this.notificationComponent.displayNotification("Authentication failed!", 'neg');

            }, 500);

          }

        })


      }

      else {
        this.emailPassResetFieldError = true;
      }

    }
    else {
      this.emailPassResetFieldError = true;
    }

  }


  public isCodeResendClicked: boolean = false;

  resendCode() {
    this.isCodeResendClicked = true;
    this.sendLoginRequest();
  }

}
