import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.css']
})
export class SecurityComponent implements OnInit {

  constructor(
    private titleService: Title,
  ) { }

  public isLoaded: boolean = false;
  ngOnInit(): void {

    this.titleService.setTitle("Kapmates • Security");

    setTimeout(() => {
      this.isLoaded = true;
    }, 2);
    
  }



}
