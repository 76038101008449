<div>
    <app-navbar></app-navbar>

    <div class="main-holder">

        <div style="text-align:left;max-width:600px;margin:auto;">

            <div [ngStyle]="{'opacity':isLoaded == true ? '1':'0'}" class="sec-header-holder">
                <span class="sec-header">Letter <span style="color:#b6e8f9;">0</span></span>
                <br>
            </div>


            <div class="security-main-window" [ngStyle]="{'opacity':isLoaded == true ? '1':'0'}"
                style="opacity:0;transition: opacity 0.25s ease-in-out;">

                <!-- encryption -->
                <div style="width:100%;">

                    <div>

                        <div>
                            <span style="color: #fff;font-size:15px;font-weight:400;line-height:1.5;">
                                Kapmates is building the core infrastructure for a new age of lending. Since its
                                inception, lending has significantly evolved to the point where anyone today could
                                easily apply & receive financing through their smartphone within minutes. While this is
                                a momentous shift, compared to the experience offered years ago, there's still plenty of
                                room for improvement.
                                <br><br>
                                We want to reimagine the foundation of lending to make it more data-driven,
                                decentralized, collaborative, and embeddable, with the end goal of helping uncover
                                "true" credit risks and make possible new forms of financing & investment opportunities.
                                We strongly believe that when true credit risks are identified, coupled with some of our
                                other approaches, credit decisions will no longer be limited to the binary structure
                                that lenders/investors are used to today, allowing for more credit approvals - which
                                benefits both lenders & borrowers.
                                <br><br>
                                The global lending industry is a massive multi-trillion-dollar opportunity, with
                                multiple products, lenders, & customer types, and we cannot cater to them all initially.
                                So, our early "direct lending" efforts & focus will be on serving commercial lending
                                products to startups and SMEs in the US, in addition to some other exciting products
                                that tie directly into our mission.
                                <br><br>
                                One of the main lending products we'll be offering directly to businesses is credit
                                lines, and to kick things off and test the waters, we've released a Corporate Card &
                                Spend Management product. We're currently looking to work with very few companies with
                                this product as we continue to build the final platform, so feel free to <span
                                    (click)="toggleAccessRequest()"
                                    style="cursor: pointer;color:#B178F6;font-weight:500;text-decoration:underline">request
                                    access</span>
                                if you're interested in using it in your company.

                                <br>
                                <br><br>
                                <span style="opacity:0.9!important">Best,<br><br>Regan <br><span
                                        style="font-size:12px;">Founder</span></span>
                            </span>

                        </div>

                    </div>

                </div>

            </div>


        </div>

        <div class="side-des-ver" style="left:-20px!important;top:10%;position: absolute;">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="492" viewBox="0 0 34 492" fill="none">
                <rect width="34" height="322" rx="17" fill="#9DE4FF" />
                <circle cx="17" cy="367" r="17" fill="#FFD384" />
                <circle cx="17" cy="421" r="17" fill="#B178F6" />
                <circle cx="17" cy="475" r="17" fill="#fff" />
            </svg>
        </div>

    </div>

    <app-request-access #requestAccessComp></app-request-access>

</div>