import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RequestAccessComponent } from 'src/app/SharedComponents/request-access/request-access.component';

@Component({
  selector: 'app-vision',
  templateUrl: './vision.component.html',
  styleUrls: ['./vision.component.css']
})
export class VisionComponent implements OnInit {
  constructor(
    private titleService: Title,
  ) { }

  public isLoaded: boolean = false;

  public currentYear: Date = new Date;

  ngOnInit(): void {

    this.titleService.setTitle("Kapmates • Vision");

    setTimeout(() => {
      this.isLoaded = true;
    }, 2);
  }



  @ViewChild('requestAccessComp') requestAccessComp: RequestAccessComponent;
  toggleAccessRequest() {
    this.requestAccessComp.toggleAccessRequest(true);
  }

}
