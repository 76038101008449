import { HttpClient } from '@angular/common/http';
import { EventEmitter, Input, Output } from '@angular/core';
import { HostListener } from '@angular/core';
import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-country-dropdown-component',
  templateUrl: './country-dropdown-component.component.html',
  styleUrls: ['./country-dropdown-component.component.css']
})
export class CountryDropdownComponentComponent implements OnInit {

  

  //dropdown search box element reference
  @ViewChild('dropdownSearchbox') dropdownSearchbox: ElementRef;

  //dropdown selected value element reference
  @ViewChild('dropSelectedValue') dropSelectedValue: ElementRef;

  //dropdown current index element reference
  @ViewChild('dropCurrentItemIndex') dropCurrentItemIndex: ElementRef;

  public dropdownToggled: boolean = false;
  public dropClickInside: boolean = false;

  public selectedValue: string = "Select country";

  public rawListItems: any = [];
  public listItems: any = [];

  @Output() selectedItem: EventEmitter<any> = new EventEmitter<any>();

  @Input('componentElementId') componentElementId: ElementRef;


  //if the dropdown is been used for an existing item, then this will be the ID of the the selected value
  //this should be used to set the select span text
  @Input("IncomingSelectedValue") IncomingSelectedValue: string;


  //This is used to indicate there or nor the dropdown should float at the top instead of down
  //this is usually set to true, if there isn't enough room for the list to be displayed below
  @Input("FloatTop") FloatTop: boolean = false;


  public FinalFloatTop: boolean = false;

  //this allows the dropdown to selectable or editable
  //if set to false, then user cannot select from it - usually when a values is already selected and cannot be changed
  @Input("IsSelectable") IsSelectable: boolean = true;

  public countryCode: string = "us";

  public isFocused: boolean = false;

  constructor(
    private httpClient: HttpClient,
  ) { }

  ngOnInit(): void {

    let countriesList: any = localStorage.getItem("countries");
    if (countriesList != null) {
      this.rawListItems = (JSON.parse(countriesList));
      setTimeout(() => {
        this.performActionsToRenderUI();
      }, 0);
    }
    else {
      this.httpClient.get("assets/JsonFiles/countries.json").subscribe(data => {
        this.rawListItems = (data);
        localStorage.setItem("countries", JSON.stringify(data));
        setTimeout(() => {
          this.performActionsToRenderUI();
        }, 0);
      });
    }

  }

  performActionsToRenderUI() {
    this.rebuildList(this.rawListItems);
    this.adjustDropLocation();
  }


  //rebuilds or enriches the incoming data for actual use
  rebuildList(list: any) {

    this.listItems = list; //empty this to prevent duplicating list
    
    if (this.IncomingSelectedValue != null && this.IncomingSelectedValue != undefined) {
      this.rawListItems.forEach(element => {

        if (this.IncomingSelectedValue == element["code"]) {

          this.dropSelectedValue.nativeElement.value = this.IncomingSelectedValue;

          this.selectedValue = element["name"];

          this.countryCode = element["code"].toLowerCase();

          return;

        }

      });
    }
    else {

      this.dropSelectedValue.nativeElement.value = this.listItems[0]?.value;

      this.selectedValue = this.listItems[0]?.name;

      this.countryCode = this.listItems[0]?.code.toLowerCase();

    }

  }

  setSelectedValues(value: string) {
    this.IncomingSelectedValue = value;
    this.rebuildList(this.rawListItems);
  }

  //toggles the dropdown list if the activator is clicked
  toggleDropdown(): void {
    if (this.IsSelectable == true) {

      try {

        this.dropMainContainerStyle();
        if (this.dropdownToggled == false) {
          this.dropCurrentItemIndex.nativeElement.value = "0";
          this.SetFocusOnCurrentItemWithIndex("start", 0);

          setTimeout(() => {
            this.dropdownSearchbox.nativeElement.focus();
          }, 0);


        }
        else {
          this.ResetDropdownPosition();
        }
        this.dropdownToggled = !this.dropdownToggled;

      } catch (error) {

      }
    }

  }

  //sets the focus to the an item with a provided index
  SetFocusOnCurrentItemWithIndex(direction, oldIndex) {
    try {

      if (direction != "start") {

        let oldElId = this.componentElementId + '_item_' + oldIndex;
        document.getElementById(oldElId).classList.remove("km-droplist-item-selected");

      }

      let newElId = this.componentElementId + '_item_' + this.dropCurrentItemIndex.nativeElement.value;
      let newElement = document.getElementById(newElId);
      newElement.classList.add("km-droplist-item-selected");
    } catch (error) {

    }
  }

  //resets the dropdown item position
  ResetDropdownPosition() {
    //reset set focus position
    let currentIndex: number = Number.parseInt(this.dropCurrentItemIndex.nativeElement.value);
    this.dropCurrentItemIndex.nativeElement.value = "0"; //set this to the currenly selected value
    this.SetFocusOnCurrentItemWithIndex("reset", currentIndex);
  }

  //handles document click even
  @HostListener('document:click')
  closeDropdown() {
    try {
      if (this.dropClickInside == false) {
        this.dropdownToggled = false;
        this.ResetDropdownPosition();
      }
      this.dropClickInside = false;
      this.HandleScrollThrowArrowNavigation("ArrowDown");
    } catch (error) {

    }
  }

  //handles click inside the component
  @HostListener('click')
  clickInside() {
    this.dropClickInside = true;
  }

  //handles keydown event from the document - for handling escape key
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape" || event.key === "Esc") {
      try {
        this.closeDropdown();
      } catch (error) {

      }
    }
  }

  //handles keydown and checks if its an arrow key
  @HostListener('keydown', ['$event'])
  onArrowKeyHandler(event: KeyboardEvent) {

    if (event.key === "ArrowUp" || event.key === "ArrowDown" && this.dropdownToggled == true) {

      let currentIndex: number = Number.parseInt(this.dropCurrentItemIndex.nativeElement.value);

      if (event.key === "ArrowUp") {

        if (currentIndex - 1 >= 0) {
          this.dropCurrentItemIndex.nativeElement.value = currentIndex - 1;
          this.SetFocusOnCurrentItemWithIndex("up", currentIndex);
        }

      }
      else if (event.key === "ArrowDown") {
        if (currentIndex + 1 <= this.listItems.length - 1) {
          this.dropCurrentItemIndex.nativeElement.value = currentIndex + 1;
          this.SetFocusOnCurrentItemWithIndex("down", currentIndex);
        }
      }

      this.HandleScrollThrowArrowNavigation(event.key)


    }

    else if (event.key === "Enter" && this.dropdownToggled == true) {

      let cIndex = parseInt(this.dropCurrentItemIndex.nativeElement.value);
      this.itemClick(this.listItems[cIndex].name, this.listItems[cIndex].code);

    }

  }

  //handles arrow movement
  HandleScrollThrowArrowNavigation(direction) {
    try {
      let newElId = this.componentElementId + '_item_' + this.dropCurrentItemIndex.nativeElement.value;
      let newElement = document.getElementById(newElId);

      let dropdownListContainer = document.getElementById(this.componentElementId + '_container');

      if (direction == "ArrowUp") {
        dropdownListContainer.scrollTop = newElement.offsetTop;
      }
      else {
        dropdownListContainer.scrollTop = newElement.offsetTop - 214.5;
      }
    } catch (error) {

    }
  }

  //handles item hover
  itemHover(itemIndex) {
    let currentIndex: number = Number.parseInt(this.dropCurrentItemIndex.nativeElement.value);
    this.dropCurrentItemIndex.nativeElement.value = itemIndex;
    this.SetFocusOnCurrentItemWithIndex("hover", currentIndex);
  }

  //handles the click of an item in thelist
  itemClick(nameIn: string, valueIn: string) {

    this.dropSelectedValue.nativeElement.value = valueIn;
    this.selectedValue = nameIn;
    this.countryCode = valueIn;
    this.closeDropdown();
    this.selectedItem.emit(true);
  }


  //handles dropdown search
  @HostListener('keyup', ['$event'])
  dropdownSearch(event: KeyboardEvent) {

    if (event.key != "ArrowUp" && event.key != "ArrowDown" && this.dropdownToggled == true) {


      let enteredSearch: any = (this.dropdownSearchbox.nativeElement.value.toLowerCase()).trim().split(" ");


      let searchresultlistItems: any = [];

      //loop through the rawListItems, and foreach item, check if the currently enter text matches each name in the list
      this.rawListItems.forEach(element => {

        //split the current item name and compare with enteredSearch
        let currentElementNameSplit: any = element["name"].toLowerCase().trim().split(" ");

        if (enteredSearch.length <= currentElementNameSplit.length) {
          let x: number = 0;
          let totalPassed: number = 0;
          let OtherPassed: boolean;
          let totalOtherPassed: number = 0;

          enteredSearch.forEach(innerElementOne => {

            //if they're equal
            if (innerElementOne == currentElementNameSplit[x]) {
              totalPassed++;
            }
            else {

              //if they're not equal, check if the length of current index of entered search text is less than
              if (innerElementOne.length < currentElementNameSplit[x].length) {

                //check if the length equivalent of currentElementNameSplit[x] is equal to innerElementOne
                if (innerElementOne == this.GetStringLengthEquivalence(innerElementOne.length, currentElementNameSplit[x])) {
                  totalPassed++;
                }

              }

            }

            x++;
          });


          let y: number;
          currentElementNameSplit.forEach(innerElementTwo => {

            //if they're not equal, check if the length of current index of entered search text is less than
            if (enteredSearch.length == 1) {
              if (innerElementTwo.length >= enteredSearch[0].length) {

                //check if the length equivalent of currentElementNameSplit[x] is equal to innerElementOne
                if (enteredSearch[0] == this.GetStringLengthEquivalence(enteredSearch[0].length, innerElementTwo)) {
                  OtherPassed = true;
                }
              }
            }

            else {

              if (innerElementTwo == enteredSearch[0]) {
                totalOtherPassed++;
                y = 1;
              }
              else {

                if (totalOtherPassed > 0) {

                  //if they're not equal, check if the length of current index of entered search text is less than
                  if (y < enteredSearch.length) {
                    if (innerElementTwo.length >= enteredSearch[y].length) {

                      //check if the length equivalent of currentElementNameSplit[x] is equal to innerElementOne
                      if (enteredSearch[y] == this.GetStringLengthEquivalence(enteredSearch[y].length, innerElementTwo)) {
                        totalOtherPassed++;
                        y++;
                      }
                      else {
                        totalOtherPassed = 1;
                        y = 1;
                      }

                    }

                    else {
                      totalOtherPassed = 1;
                      y = 1;
                    }
                  }

                }

              }

            }


          });

          if (totalPassed == enteredSearch.length) {
            searchresultlistItems.push(element);
          }
          else {
            if (OtherPassed == true || totalOtherPassed > 1) {
              searchresultlistItems.push(element);
            }
          }
        }

      });

      if (searchresultlistItems.length > 0) {
        this.listItems = searchresultlistItems;
        this.dropCurrentItemIndex.nativeElement.value = "0";
        this.SetFocusOnCurrentItemWithIndex("start", 0);
        this.dropMainContainerStyle();

      }
      else {
        this.listItems = [];
      }


    }
    else if (event.key == "Enter" && this.dropdownToggled == false && this.isFocused == true) {
      this.toggleDropdown();
      this.isFocused = false;
    }

  }

  //gets part of a string from a string with a specified length
  GetStringLengthEquivalence(length: number, word: string): string {

    let x: number = 0;
    let build: string = "";

    while (x < length) {

      build = build + word[x];

      x++;
    }

    return build;
  }

  //this sets the style for the dropdown list container: basically where the dropdown should be positioned
  dropMainContainerStyle() {

    let dropel = document.getElementById(this.componentElementId + '_activator');
    let dropCHolder = document.getElementById(this.componentElementId + '_dropdownholder');

    let elTop = dropel.getBoundingClientRect().top;
    let winHeight = window.innerHeight;
    let space = winHeight - elTop;

    let allowableSpace = dropCHolder.scrollHeight + 150;

    if (space < allowableSpace) {
      dropCHolder.setAttribute("style", "bottom:38px !important");

    }
    else {
      //return { "top": "3px!important" };
      dropCHolder.setAttribute("style", "top:3px !important")
    }
  }

  @HostListener('window:resize', ['$event'])
  adjustDropLocation() {
    try {
      this.dropMainContainerStyle();
    } catch (error) {

    }
  }



  collectData(): string {
    return this.dropSelectedValue.nativeElement.value.toUpperCase();
  }

  activatorFocusHandler(stat: boolean) {
    this.isFocused = stat;
  }

}
