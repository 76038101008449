import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EmailValidatorClass } from 'src/app/Services/GeneralClasses/email-validator-class.service';
import { ApiRequestHandlerService } from 'src/app/Services/GeneralServices/api-request-handler.service';
import { CookieHandlingServicesService } from 'src/app/Services/GeneralServices/cookie-handling-services.service';
import { StandardSearchableDropdownComponent } from '../Dropdown/standard-searchable-dropdown/standard-searchable-dropdown.component';
import { NotificationListComponentComponent } from '../notification-list-component/notification-list-component.component';

@Component({
  selector: 'app-request-access',
  templateUrl: './request-access.component.html',
  styleUrls: ['./request-access.component.css']
})
export class RequestAccessComponent implements OnInit {

  constructor(
    private apiService: ApiRequestHandlerService,
  ) { }

  public notificationComponent: NotificationListComponentComponent = new NotificationListComponentComponent();

  public teamsizeOptions: any = [
    { value: '1 - 3', name: '1 - 3 employees' },
    { value: '4 - 10', name: '4 - 10 employees' },
    { value: '10 - 20', name: '10 - 20 employees' },
    { value: '20 - 50', name: '20 - 50 employees' },
    { value: '50 - 100', name: '50 - 100 employees' },
    { value: '100 - 200', name: '100 - 200 employees' },
    { value: '200+', name: '200+ employees' },
  ]

  public companyMonthlySpend: any = [
    { value: '<= $1,000', name: 'Up to $1,000' },
    { value: '$1,000 - $5,000', name: '$1,000 - $5,000' },
    { value: '$5,000 - $20,000', name: '$10,000 - $20,000' },
    { value: '$20,000 - $50,000', name: '$20,000 - $50,000' },
    { value: '$50,000 - $100,000', name: '$50,000 - $100,000' },
    { value: '$100,000+', name: '$100,000+' },
  ]

  ngOnInit(): void {
  }

  @ViewChild('requesterName') requesterName: ElementRef;
  @ViewChild('requesterEmail') requesterEmail: ElementRef;
  @ViewChild('requesterCompanyName') requesterCompanyName: ElementRef;
  @ViewChild('requesterRole') requesterRole: ElementRef;
  @ViewChild('companySizeComp') companySizeComp: StandardSearchableDropdownComponent;
  @ViewChild('companyMonthlySpendComp') companyMonthlySpendComp: StandardSearchableDropdownComponent;

  public requestButtonOneLoading: boolean = false;

  sendAccessRequest() {

    let proceed: boolean = true;

    let name: string = this.requesterName.nativeElement.value;
    let email: string = this.requesterEmail.nativeElement.value;
    let companyName: string = this.requesterCompanyName.nativeElement.value;
    let role: string = this.requesterRole.nativeElement.value;
    let monthlySpend: string = this.companyMonthlySpendComp.collectData();
    let teamsize: string = this.companySizeComp.collectData();

    if (name.length == 0) { this.nameError = true; proceed = false }
    if (email.length == 0) { this.emailError = true; proceed = false }
    if (companyName.length == 0) { this.companyNameError = true; proceed = false }
    if (role.length == 0) { this.companyRoleError = true; proceed = false }

    if (proceed == true) {

      let emailValidator: EmailValidatorClass = new EmailValidatorClass();
      if (emailValidator.validate(email) == true) {

        this.requestButtonOneLoading = true;

        let payload: any = {
          requester_email: email,
          requester_name: name,
          team_size: teamsize,
          monthly_spend_range: monthlySpend,
          company_name: companyName,
          role_in_company: role,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }

        this.apiService.sendAccessRequest(payload).subscribe({

          next: data => {

            if (data.success == true) {

              setTimeout(() => {

                this.requestButtonOneLoading = false;

                this.requesterName.nativeElement.value = '';
                this.requesterEmail.nativeElement.value = '';
                this.requesterCompanyName.nativeElement.value = '';
                this.requesterRole.nativeElement.value = '';
                this.companyMonthlySpendComp.setSelectedValues('<= $1,000');
                this.companySizeComp.setSelectedValues('1 - 3');

                this.notificationComponent.displayNotification("Great! We'll be in touch very soon!", 'pos');
                this.isWindowOpen = false;

              }, 500);

            }
            //success == false
            else {
              setTimeout(() => {

                this.requestButtonOneLoading = false;
                this.notificationComponent.displayNotification(data.message, 'neg');

              }, 300);
            }

          },

          error: () => {

            this.requestButtonOneLoading = false;
            this.notificationComponent.displayNotification("An error occurred!", 'neg');

          }

        })

      }
      else {
        this.emailError = true;
      }

    }


  }

  public isWindowOpen: boolean = false;
  toggleAccessRequest(stat: boolean) {
    this.isWindowOpen = stat;
  }

  public emailError: boolean = false;
  public nameError: boolean = false;
  public companyNameError: boolean = false;
  public companyRoleError: boolean = false;

  checkBasicInfoChange() {

    if (this.requesterName.nativeElement.value.length > 0) {
      this.nameError = false;
    }

    if (this.requesterEmail.nativeElement.value.length > 0) {
      this.emailError = false;
    }

    if (this.requesterCompanyName.nativeElement.value.length > 0) {
      this.companyNameError = false;
    }

    if (this.requesterRole.nativeElement.value.length > 0) {
      this.companyRoleError = false;
    }

  }

}
