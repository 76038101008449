import { HttpClient } from '@angular/common/http';
import { Output } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-phone-number-input-component',
  templateUrl: './phone-number-input-component.component.html',
  styleUrls: ['./phone-number-input-component.component.css']
})
export class PhoneNumberInputComponentComponent implements OnInit {

  //dropdown search box element reference
  @ViewChild('dropdownSearchbox') dropdownSearchbox: ElementRef;

  @ViewChild('phoneNumberInput') phoneNumberInput: ElementRef;

  //dropdown selected value element reference
  @ViewChild('dropSelectedValue') dropSelectedValue: ElementRef;

  //dropdown current index element reference
  @ViewChild('dropCurrentItemIndex') dropCurrentItemIndex: ElementRef;

  public dropdownToggled: boolean = false;
  public dropClickInside: boolean = false;

  public selectedValue: string = "+1";

  public countryCode: string = "us";

  public phoneNumberFormat: string = "";

  public countryNumberExamplePlaceholcer = "";

  public phoneInputFocused: boolean = false;

  public rawListItems: any = [];
  public listItems: any = [];


  @Input('componentElementId') componentElementId: string;

  //if the dropdown is been used for an existing item, then this will be the ID of the the selected value
  //this should be used to set the select span text
  @Input("IncomingSelectedValue") IncomingSelectedValue: string = "us";

  @Input('IncomingPhoneNumber') IncomingPhoneNumber: string;

  //when phone input is keyedup, send an event to the parent
  @Output() PhoneInputKeyDown: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private httpClient: HttpClient,
  ) { }


  ngOnInit(): void {

    let countriesList: any = localStorage.getItem("countries");
    if (countriesList != null) {
      this.rawListItems = (JSON.parse(countriesList));
      setTimeout(() => {
        this.performActionsToRenderUI();
      }, 0);
    }
    else {
      this.httpClient.get("assets/JsonFiles/countries.json").subscribe(data => {
        this.rawListItems = (data);
        localStorage.setItem("countries", JSON.stringify(data));
        setTimeout(() => {
          this.performActionsToRenderUI();
        }, 0);
      });
    }

  }

  performActionsToRenderUI() {
    this.rebuildList(this.rawListItems);
    this.adjustDropLocation();
    //set phone number field with value passed to the component
    if (this.IncomingPhoneNumber != null && this.IncomingPhoneNumber != undefined) {
      this.phoneNumberInput.nativeElement.value = this.IncomingPhoneNumber;
    }
  }


  //rebuilds or enriches the incoming data for actual use
  rebuildList(list: any) {

    this.listItems = list; //empty this to prevent duplicating list

    if (this.IncomingSelectedValue != null && this.IncomingSelectedValue != undefined) {
      this.rawListItems.forEach(element => {

        if (this.IncomingSelectedValue == element["code"]) {

          this.dropSelectedValue.nativeElement.value = this.IncomingSelectedValue.toLowerCase();

          this.selectedValue = element["dial_code"];

          this.countryCode = element["code"].toLowerCase();

          return;

        }

      });
    }
    else {

      this.dropSelectedValue.nativeElement.value = this.listItems[0]?.code;

      this.selectedValue = this.listItems[0]?.dial_code;

      this.countryCode = this.listItems[0]?.code.toLowerCase();

    }



  }

  setSelectedValues(countryCode: string, phoneNumber: string): void {
    this.IncomingSelectedValue = countryCode.toUpperCase();
    this.rebuildList(this.rawListItems);
    this.phoneNumberInput.nativeElement.value = phoneNumber;
  }


  phoneFocus(stat) {
    this.phoneInputFocused = stat;
    if (stat == true) {
      this.dropClickInside = true;
      this.dropdownToggled = false;
    }
  }

  //toggles the dropdown list if the activator is clicked
  toggleDropdown(): void {

    try {
      if (this.dropdownToggled == false) {
        this.dropMainContainerStyle();
        this.dropCurrentItemIndex.nativeElement.value = "0";
        this.SetFocusOnCurrentItemWithIndex("start", 0);
        setTimeout(() => {
          this.dropdownSearchbox.nativeElement.focus();
        }, 0);
        this.phoneInputFocused = true;
      }
      else {

        this.ResetDropdownPosition();

      }
      this.dropdownToggled = !this.dropdownToggled;
    } catch (error) {

    }


  }

  //handles document click even
  @HostListener('document:click')
  closeDropdown() {
    try {
      if (this.dropClickInside == false) {
        this.dropdownToggled = false;
        this.phoneInputFocused = false;
        this.ResetDropdownPosition();
      }

      this.dropClickInside = false;
      this.HandleScrollThrowArrowNavigation("ArrowDown");
    } catch (error) {

    }
  }

  //handles click inside the component
  @HostListener('click')
  clickInside() {
    this.dropClickInside = true;
  }

  //handles item hover
  itemHover(itemIndex) {
    let currentIndex: number = Number.parseInt(this.dropCurrentItemIndex.nativeElement.value);
    this.dropCurrentItemIndex.nativeElement.value = itemIndex;
    this.SetFocusOnCurrentItemWithIndex("hover", currentIndex);
  }

  //sets the focus to the an item with a provided index
  SetFocusOnCurrentItemWithIndex(direction, oldIndex) {
    if (direction != "start") {

      let oldElId = this.componentElementId + '_item_' + oldIndex;
      document.getElementById(oldElId).classList.remove("km-droplist-item-selected");

    }

    let newElId = this.componentElementId + '_item_' + this.dropCurrentItemIndex.nativeElement.value;
    let newElement = document.getElementById(newElId);
    newElement.classList.add("km-droplist-item-selected");

  }

  //handles the click of an item in thelist
  itemClick(codeIn: string, dialCodeIn: string) {
    this.dropSelectedValue.nativeElement.value = codeIn.toLowerCase();
    this.selectedValue = dialCodeIn;
    this.countryCode = codeIn.toLowerCase();
    this.closeDropdown();
    this.phoneNumberInput.nativeElement.focus();
    this.PhoneInputKeyDown.emit(true); //emit to the parent component - some will need to know if country is seleted
  }



  //resets the dropdown item position
  ResetDropdownPosition() {
    //reset set focus position
    let currentIndex: number = Number.parseInt(this.dropCurrentItemIndex.nativeElement.value);
    this.dropCurrentItemIndex.nativeElement.value = "0"; //set this to the currenly selected value
    this.SetFocusOnCurrentItemWithIndex("reset", currentIndex);
  }

  //handles keydown event from the document - for handling escape key
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape" || event.key === "Esc") {
      try {
        this.closeDropdown();
      } catch (error) {

      }
    }
  }

  //handles keydown and checks if its an arrow key
  @HostListener('keydown', ['$event'])
  onArrowKeyHandler(event: KeyboardEvent) {

    if (event.key === "ArrowUp" || event.key === "ArrowDown" && this.dropdownToggled == true) {

      let currentIndex: number = Number.parseInt(this.dropCurrentItemIndex.nativeElement.value);

      if (event.key === "ArrowUp") {

        if (currentIndex - 1 >= 0) {
          this.dropCurrentItemIndex.nativeElement.value = currentIndex - 1;
          this.SetFocusOnCurrentItemWithIndex("up", currentIndex);
        }

      }
      else if (event.key === "ArrowDown") {
        if (currentIndex + 1 <= this.listItems.length - 1) {
          this.dropCurrentItemIndex.nativeElement.value = currentIndex + 1;
          this.SetFocusOnCurrentItemWithIndex("down", currentIndex);
        }
      }

      this.HandleScrollThrowArrowNavigation(event.key)


    }

    else if (event.key === "Enter" && this.dropdownToggled == true) {

      let cIndex = parseInt(this.dropCurrentItemIndex.nativeElement.value);
      this.itemClick(this.listItems[cIndex].code, this.listItems[cIndex].dial_code);

    }

  }

  //handles arrow movement
  HandleScrollThrowArrowNavigation(direction) {
    let newElId = this.componentElementId + '_item_' + this.dropCurrentItemIndex.nativeElement.value;
    let newElement = document.getElementById(newElId);

    let dropdownListContainer = document.getElementById(this.componentElementId + '_container');

    if (direction == "ArrowUp") {
      dropdownListContainer.scrollTop = newElement.offsetTop;
    }
    else {
      dropdownListContainer.scrollTop = newElement.offsetTop - 214.5;
    }

  }


  //handles dropdown search
  @HostListener('keyup', ['$event'])
  dropdownSearch(event: KeyboardEvent) {

    if (event.key != "ArrowUp" && event.key != "ArrowDown" && this.dropdownToggled == true) {


      let enteredSearch: any = (this.dropdownSearchbox.nativeElement.value.toLowerCase()).trim().split(" ");

      // console.log(enteredSearch);


      let searchresultlistItems: any = [];

      //loop through the rawListItems, and foreach item, check if the currently enter text matches each name in the list
      this.rawListItems.forEach(element => {

        //split the current item name and compare with enteredSearch
        let currentElementNameSplit: any = (element["dial_code"] + ' ' + element["dial_code"].replace('+', '') + ' ' + element["code"].toLowerCase() + ' ' + element["name"].toLowerCase().trim()).split(" ");

        if (enteredSearch.length <= currentElementNameSplit.length) {
          let x: number = 0;
          let totalPassed: number = 0;
          let OtherPassed: boolean;
          let totalOtherPassed: number = 0;

          enteredSearch.forEach(innerElementOne => {

            //if they're equal
            if (innerElementOne == currentElementNameSplit[x]) {
              totalPassed++;
            }
            else {

              //if they're not equal, check if the length of current index of entered search text is less than
              if (innerElementOne.length < currentElementNameSplit[x].length) {

                //check if the length equivalent of currentElementNameSplit[x] is equal to innerElementOne
                if (innerElementOne == this.GetStringLengthEquivalence(innerElementOne.length, currentElementNameSplit[x])) {
                  totalPassed++;
                }

              }

            }

            x++;
          });


          let y: number;
          currentElementNameSplit.forEach(innerElementTwo => {

            //if they're not equal, check if the length of current index of entered search text is less than
            if (enteredSearch.length == 1) {
              if (innerElementTwo.length >= enteredSearch[0].length) {

                //check if the length equivalent of currentElementNameSplit[x] is equal to innerElementOne
                if (enteredSearch[0] == this.GetStringLengthEquivalence(enteredSearch[0].length, innerElementTwo)) {
                  OtherPassed = true;
                }
              }
            }

            else {

              if (innerElementTwo == enteredSearch[0]) {
                totalOtherPassed++;
                y = 1;
              }
              else {

                if (totalOtherPassed > 0) {

                  //if they're not equal, check if the length of current index of entered search text is less than
                  if (y < enteredSearch.length) {
                    if (innerElementTwo.length >= enteredSearch[y].length) {

                      //check if the length equivalent of currentElementNameSplit[x] is equal to innerElementOne
                      if (enteredSearch[y] == this.GetStringLengthEquivalence(enteredSearch[y].length, innerElementTwo)) {
                        totalOtherPassed++;
                        y++;
                      }
                      else {
                        totalOtherPassed = 1;
                        y = 1;
                      }

                    }

                    else {
                      totalOtherPassed = 1;
                      y = 1;
                    }
                  }

                }

              }

            }


          });

          if (totalPassed == enteredSearch.length) {
            searchresultlistItems.push(element);
          }
          else {
            if (OtherPassed == true || totalOtherPassed > 1) {
              searchresultlistItems.push(element);
            }
          }
        }

      });

      if (searchresultlistItems.length > 0) {

        this.listItems = searchresultlistItems;

        this.dropCurrentItemIndex.nativeElement.value = "0";
        this.SetFocusOnCurrentItemWithIndex("start", 0);
        this.dropMainContainerStyle();

      }
      else {
        this.listItems = [];
      }

    }

  }

  //this sets the style for the dropdown list container: basically where the dropdown should be positioned
  dropMainContainerStyle() {

    let dropel = document.getElementById(this.componentElementId + '_activator');
    let dropCHolder = document.getElementById(this.componentElementId + '_dropdownholder');

    let elTop = dropel.getBoundingClientRect().top;
    let winHeight = window.innerHeight;
    let space = winHeight - elTop;

    let allowableSpace = dropCHolder.scrollHeight + 150;

    if (space < allowableSpace) {
      dropCHolder.setAttribute("style", "bottom:38px !important");

    }
    else {
      //return { "top": "3px!important" };
      dropCHolder.setAttribute("style", "top:3px !important")
    }
  }

  @HostListener('window:resize', ['$event'])
  adjustDropLocation() {
    try {
      this.dropMainContainerStyle();
    } catch (error) {

    }
  }

  //gets part of a string from a string with a specified length
  GetStringLengthEquivalence(length: number, word: string): string {

    let x: number = 0;
    let build: string = "";

    while (x < length) {

      build = build + word[x];

      x++;
    }

    return build;
  }

  //collects the phone number
  collectData(): PhoneNumberRetrieveClass {

    let response: PhoneNumberRetrieveClass = new PhoneNumberRetrieveClass();

    if (this.phoneNumberInput.nativeElement.value.length > 0) {

      //if dropSelectedValue is populated use its value, else, use the IncomingSelectedValue value
      if (this.dropSelectedValue.nativeElement.value.length > 0) {
        response.CountryCode = this.dropSelectedValue.nativeElement.value;

      }
      else {
        response.CountryCode = this.IncomingSelectedValue;
      }

      response.CountryCodeNumber = this.selectedValue;

      response.Phone = this.phoneNumberInput.nativeElement.value;
      response.IsComplete = true;

    }

    return response;

  }

  formatPhoneInput() {

    if (this.phoneNumberInput.nativeElement.value.length > 0) {
      this.PhoneInputKeyDown.emit(true);
    }

    let input = this.phoneNumberInput.nativeElement.value;

    let splitString: any = input.split("");
    let allNumbersString: any = [];
    input.split("").forEach(element => {

      if (parseInt(element) || element == '0') {
        allNumbersString.push(element);
      }

    });

    let numerString: string = "";
    if (allNumbersString.length > 0) {
      allNumbersString.forEach(element => {
        numerString = numerString + element;
      });
    }


    if (numerString != "") {

      this.phoneNumberInput.nativeElement.value = numerString;

    }
    else {
      this.phoneNumberInput.nativeElement.value = "";
    }

  }


  formaterFunction(numerString: string, phoneNumberFormat: string): string {

    let formatSplit = phoneNumberFormat.split("");
    let incomingNumberSplit = numerString.split("");

    let newString: string = "";

    let openBrackIndex: number = -1;
    let closeBrackIndex: number = -1;

    let spaceIndexes: any = [];
    let dashIndexes: any = [];

    let x: number = 0;
    let totalInFormat: number = 0;
    let totalInEntered: number = 0;
    formatSplit.forEach(element => {

      if (element == "(") {
        openBrackIndex = x;
      }

      else if (element == ")") {
        closeBrackIndex = x - 2;
      }

      else if (element == " ") {
        if (phoneNumberFormat.includes("(")) {
          spaceIndexes.push(x - 2);
        }
        else {
          spaceIndexes.push(x);
        }
      }

      else if (element == "-") {
        if (phoneNumberFormat.includes("(")) {
          dashIndexes.push(x - 2);
        }
        else {
          dashIndexes.push(x);
        }
      }

      else if (element == "x") {
        totalInFormat++;
      }

      x++;
    });

    x = 0;
    let brackCollected: boolean = false;
    let collectedBrackAddedToString = false;
    let buildingBrack: boolean = false;
    let builtBracket: string = "";
    incomingNumberSplit.forEach(element => {

      if (x == openBrackIndex) {
        builtBracket = "(" + element;
        buildingBrack = true;
      }
      else if (x == closeBrackIndex) {
        brackCollected = true;
        builtBracket += element + ")";
        buildingBrack = false;
      }
      else {
        if (buildingBrack == true) {
          builtBracket += element;
        }
      }

      if (brackCollected == true && collectedBrackAddedToString == false) {
        newString = builtBracket;
        collectedBrackAddedToString = true;
      }
      else {

        if (totalInEntered + 1 < totalInFormat) {
          newString += element;
          totalInEntered++;
        }

      }

      spaceIndexes.forEach(innerelement => {
        if (innerelement == x + 1) {
          newString += " ";
        }
      });

      dashIndexes.forEach(innerelement => {
        if (innerelement == x + 2) {
          newString += "-";
        }
      });


      x++;
    });



    return newString;

  }

}

export class PhoneNumberRetrieveClass {

  IsComplete: boolean = false;

  CountryCode: string;

  CountryCodeNumber: string;

  Phone: string;

}
