import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { StandardSearchableDropdownComponent } from '../Dropdown/standard-searchable-dropdown/standard-searchable-dropdown.component';

@Component({
  selector: 'app-address-module-component',
  templateUrl: './address-module-component.component.html',
  styleUrls: ['./address-module-component.component.css']
})


export class AddressModuleComponentComponent implements OnInit {

  constructor() { }

  @Input("AddressStateDropdownId") AddressStateDropdownId: string;

  @Input("AddressCountryDropdownId") AddressCountryDropdownId: string;

  @Input("isCountryDropSelectable") isCountryDropSelectable: boolean = true;


  @ViewChild("addressLineOne") addressLineOne: ElementRef;

  @ViewChild("addressLineTwo") addressLineTwo: ElementRef;

  @ViewChild("addressCity") addressCity: ElementRef;

  @ViewChild("addressZip") addressZip: ElementRef;

  @ViewChild('addressCountryComponent') addressCountryComponent: StandardSearchableDropdownComponent;

  @ViewChild('addressStateComponent') addressStateComponent: StandardSearchableDropdownComponent;

  public addressInputValidationObject: any = {};

  @Input('SelectedStateValue') SelectedStateValue: string;

  @Input('SelectedCountryValue') SelectedCountryValue: string;

  @Input('isAddressDisabled') isAddressDisabled: boolean = false; // used to disable address input - this is usually for cases where there's already and address that shouldn't be edited

  @Input('isStateSelectable') isStateSelectable: boolean = true;

  @Output() InputTrigger: EventEmitter<boolean> = new EventEmitter<boolean>();


  ngOnInit(): void {


  }


  collectData(): AddressCollect {

    let response: AddressCollect = new AddressCollect();

    response.IsComplete = this.checkNecessaryFields();

    if (response.IsComplete == true) {
      response.Address.line_1 = this.addressLineOne.nativeElement.value;
      response.Address.line_2 = this.addressLineTwo.nativeElement.value;
      response.Address.city = this.addressCity.nativeElement.value;
      response.Address.country = this.addressCountryComponent.collectData();
      response.Address.state = this.addressStateComponent.collectData();
      response.Address.zip = this.addressZip.nativeElement.value;
    }

    return response;
  }

  checkNecessaryFields(): boolean {

    let proceed: boolean = true;

    //check address line 1
    if (this.addressLineOne.nativeElement.value.length < 1) {
      proceed = false;
      this.addressInputValidationObject.LineOne = true;
    }
    else {
      this.addressInputValidationObject.LineOne = false;
    }

    //cehck city
    if (this.addressCity.nativeElement.value.length < 1) {
      proceed = false;
      this.addressInputValidationObject.City = true;
    }
    else {
      this.addressInputValidationObject.City = false;
    }

    //check zip
    if (this.addressZip.nativeElement.value.length < 1) {
      proceed = false;
      this.addressInputValidationObject.Zip = true;
    }
    else {
      this.addressInputValidationObject.Zip = false;
    }

    return proceed;
  }

  dropDownTriggerHandler(e) {
    this.InputTrigger.emit(true);
  }

  inputKeyUpHandler() {

    this.InputTrigger.emit(true);

    //check address line 1
    if (this.addressLineOne.nativeElement.value.length > 0) {

      this.addressInputValidationObject.LineOne = false;
    }

    //cehck city
    if (this.addressCity.nativeElement.value.length > 0) {

      this.addressInputValidationObject.City = false;
    }

    //check zip
    if (this.addressZip.nativeElement.value.length > 0) {

      this.addressInputValidationObject.Zip = false;
    }

  }


  //for new company, this sets the country to "US" which is currently the country allowed for businesses
  setCountryDropdown(value: string): void {
    this.SelectedCountryValue = value;
  }

  populateCountryDropdown(country: string) {
    this.addressCountryComponent.setSelectedValues(country);
  }

  populateStateDropdown(state: string) {
    this.addressStateComponent.setSelectedValues(state);
  }

  cleanView() {
    this.addressLineOne.nativeElement.value = '';
    this.addressLineTwo.nativeElement.value = '';
    this.addressCity.nativeElement.value = '';
    this.addressZip.nativeElement.value = '';
    this.addressInputValidationObject.LineOne = false;
    this.addressInputValidationObject.City = false;
    this.addressInputValidationObject.Zip = false;
  }

}

export class AddressObject {

  line_1: string = null;
  line_2: string = null;
  city: string = null;
  state: string = null;
  country: string = null;
  zip: string = null;
}

export class AddressCollect {
  IsComplete: boolean = false;
  Address: AddressObject = new AddressObject();
}