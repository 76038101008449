import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-list-component',
  templateUrl: './notification-list-component.component.html',
  styleUrls: ['./notification-list-component.component.css']
})
export class NotificationListComponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

  displayNotification(text: string, type: string) {

    let id = (Math.floor((Math.random() * 1200) + 1)).toString();

    let notifParent = document.getElementById("notification-list-parent");

    let notiItem = document.createElement('div');
    notiItem.className = "notification-list-item";
    notiItem.id = 'notif-item-' + id;

    let notifDot = document.createElement('div');

    if (type == "neg") {
      notifDot.className = "notification-item-dot-red";
    }
    else {
      notifDot.className = "notification-item-dot-green";
    }

    let notifText = document.createElement('span');
    notifText.innerText = text;

    notiItem.appendChild(notifDot);
    notiItem.appendChild(notifText);

    notifParent.appendChild(notiItem);

    setTimeout(() => {
      notiItem.style.opacity = "1";
    }, 200);

    setTimeout(() => {
      notiItem.style.opacity = "0";
      setTimeout(() => {
        notifParent.removeChild(notiItem);
      }, 1000);
    }, 3100);


  }

}
