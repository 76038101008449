<div class="km-sign-in-container">

    <app-navbar></app-navbar>

    <div [ngStyle]="{'display':showSignin == true ? 'block':'none'}" class="km-signin-window">


        <div style="opacity:0;transition: opacity 0.35s ease-in-out;"
            [ngStyle]="{'opacity':showSignInAnimated == true ? '1':'0'}">

            <div style="margin-top:1px;">
                <span class="km-sign-in-text">Reset password
                </span>
            </div>


            <div style="margin-top:20px;">

                <!-- password -->
                <div class="km-form-item-container">
                    <div class="km-form-item-header-container">
                        <div class="field-error-div" [ngStyle]="{'display':passOneFieldError ? 'inline-block':'none'}">
                        </div>
                        <span class="km-form-item-title-span">New password</span>
                    </div>
                    <div style="width: 100%;">
                        <input class="km-input" (keyup)="inputKeyUpHandler()" type="text" #userNewPasswordInput
                            type="password" name="password">
                    </div>
                </div>

                <!-- re-enter password -->
                <div class="km-form-item-container">
                    <div class="km-form-item-header-container">
                        <div class="field-error-div" [ngStyle]="{'display':passTwoFieldError ? 'inline-block':'none'}">
                        </div>
                        <span class="km-form-item-title-span">Confirm new password</span>
                    </div>
                    <div style="width: 100%;">
                        <input class="km-input" (keyup)="inputKeyUpHandler()" type="text" #userReNewPasswordInput
                            type="password" name="password">
                    </div>

                </div>


                <div style="margin-top:30px;text-align: right;">
                    <div style="width:55px;" class="km-button km-button-positive" (click)="sendResetRequest()"
                        [ngClass]="{'km-button-clicked':resetButtonOneLoading == true}">
                        <div class="km-btn-spinner"
                            [ngClass]="{'km-btn-spinner-show':resetButtonOneLoading == true}">
                        </div>
                        <span [ngClass]="{'km-button-span-hide':resetButtonOneLoading == true}">Proceed</span>
                    </div>
                </div>


            </div>



        </div>


    </div>

</div>