<div class="km-phone-number-element-main-container">
    <div class="km-phone-number-element-container" style="width:calc(100% - 14px)!important"
        [ngClass]="{'km-phone-number-element-container-focused':phoneInputFocused == true }"
        id="{{componentElementId}}_activator">
        <div class="km-phone-number-country-selector-container">

            <div (click)="toggleDropdown()" class="km-dropdown-activator-holder-phone-country">
                <div class="km-dropdown-country-selected-text-span-holder-div">
                    <span class="km-dropdown-country-code-span-text"
                        [ngStyle]="{'display':selectedValue.length < 3 ? 'inline':'none'}"
                        style="margin-left: 2px!important;">{{countryCode.toUpperCase()}} • </span>
                    <span class="km-dropdown-country-code-span-text"
                        style="margin-left: 2px!important;">{{selectedValue}}</span>
                </div>
                <div class="km-dropdown-arrow-svg-holder-div">
                    <svg class="km-dropdown-arrow-svg" xmlns="http://www.w3.org/2000/svg" width="13" height="13"
                        viewBox="0 0 16 16" fill="none">
                        <path
                            d="M7.99998 15.12C7.82076 15.12 7.64156 15.0465 7.50493 14.8999L3.20514 10.2835C2.93162 9.98982 2.93162 9.5137 3.20514 9.22016C3.47855 8.92661 3.92193 8.92661 4.19548 9.22016L7.99998 13.305L11.8045 9.2203C12.078 8.92676 12.5214 8.92676 12.7948 9.2203C13.0684 9.51384 13.0684 9.98996 12.7948 10.2836L8.49504 14.9C8.35834 15.0467 8.17914 15.12 7.99998 15.12Z"
                            fill="#292929" />
                        <path
                            d="M8.00002 0.999995C8.17924 0.999995 8.35844 1.07346 8.49507 1.22009L12.7949 5.83651C13.0684 6.13017 13.0684 6.6063 12.7949 6.89984C12.5214 7.19338 12.0781 7.19338 11.8045 6.89984L8.00002 2.81497L4.19549 6.8997C3.92197 7.19324 3.47863 7.19324 3.20524 6.8997C2.93159 6.60615 2.93159 6.13003 3.20524 5.83637L7.50496 1.21995C7.64166 1.0733 7.82086 0.999995 8.00002 0.999995Z"
                            fill="#292929" />
                    </svg>
                </div>
            </div>


        </div>

        <div class="km-phone-number-input-container">
            <input type="text" (focus)="phoneFocus(true)" #phoneNumberInput (focusout)="phoneFocus(false)"
                (keyup)="formatPhoneInput()" class="phone-number-input"
                placeholder="{{countryNumberExamplePlaceholcer}}" name="phone-number">
        </div>
    </div>

    <div style="position:relative;max-width: 90px;">

        <div [ngClass]="{'km-dropdown-list-close':dropdownToggled == false, 'km-dropdown-list-open':dropdownToggled == true }"
            class="km-phone-dropdown-list-container km-dropdown-list-close" id="{{componentElementId}}_dropdownholder">
            <div class="dropdown-search-input-container">
                <input type="text" tabindex="-1" autocomplete="off" placeholder="Search" #dropdownSearchbox
                    class="dropdown-search-input">
                <input type="text" #dropSelectedValue style="display: none;">
            </div>

            <div class="km-main-drop-list-holder" id="{{componentElementId}}_container">

                <input #dropCurrentItemIndex type="text" style="display:none;">

                <div #listItemEl class="km-droplist-standard-item"
                    *ngFor="let listItem of listItems;index as i; last as islast" [attr.data-value]="listItem.code"
                    [attr.data-name]="listItem.code" [attr.id]="componentElementId + '_item_' + i"
                    (click)="itemClick(listItem.code,listItem.dial_code)" (mouseover)="itemHover(i)">
                    <div class="km-droplist-item-text-holder">
                        <span class="km-dropdown-option-standard-text-span"
                            style="margin-left: 6px!important;font-size:13px!important;font-weight: 500!important;">{{listItem.code.toUpperCase()}}
                            • </span>
                        <span class="km-dropdown-option-standard-text-span"
                            style="margin-left: 2px!important;font-size:13px!important;font-weight: 500!important;">{{listItem.dial_code}}</span>
                    </div>
                </div>

            </div>

        </div>
    </div>

</div>