<div class="km-sign-in-container">

    <app-navbar></app-navbar>

    <div [ngStyle]="{'display':showSignin == true ? 'block':'none'}" class="km-signin-window">

        <div style="opacity:0;transition: opacity 0.35s ease-in-out;"
            [ngStyle]="{'opacity':showSignInAnimated == true ? '1':'0'}">

            <div style="margin-top:1px;">
                <span class="km-sign-in-text">{{getStateText()}}
                </span>
            </div>

            <div style="margin-top:20px;">

                <div [ngStyle]="{'display':currentView == 'signin' ? 'block' : 'none'}">


                    <!-- email -->
                    <div class="km-form-item-container">
                        <div class="km-form-item-header-container">
                            <div class="field-error-div"
                                [ngStyle]="{'display':emailFieldError ? 'inline-block':'none'}">
                            </div>
                            <span class="km-form-item-title-span">Email</span>
                        </div>
                        <div style="width: 100%;">
                            <input (keyup)="inputKeyUpHandler()" class="km-input" maxlength="100" type="text"
                                #userEmailInput name="email">
                        </div>

                    </div>

                    <!-- password -->
                    <div class="km-form-item-container">
                        <div class="km-form-item-header-container">
                            <div class="field-error-div" [ngStyle]="{'display':passFieldError ? 'inline-block':'none'}">
                            </div>
                            <span class="km-form-item-title-span">Password</span>
                        </div>
                        <div style="width: 100%;">
                            <input class="km-input" (keyup)="inputKeyUpHandler()" type="text" #userPasswordInput
                                type="password" name="password">
                        </div>

                    </div>

                    <div style="margin-top:30px;text-align: right;">
                        <div style="width:60px;" class="km-button km-button-positive" (click)="sendLoginRequest()"
                            [ngClass]="{'km-button-clicked':signInButtonOneLoading == true}">
                            <div class="km-btn-spinner"
                                [ngClass]="{'km-btn-spinner-show':signInButtonOneLoading == true}">
                            </div>
                            <span [ngClass]="{'km-button-span-hide':signInButtonOneLoading == true}">Continue</span>
                        </div>
                    </div>

                    <div style="text-align: right;display:block;margin-top: 15px;">
                        <span (click)="togglePassReset('resetpass')"
                            style="font-size:12px;font-weight:500;color:#9144DD;cursor: pointer;">Forgot your
                            password?</span>
                    </div>

                </div>

                <div [ngStyle]="{'display':currentView == 'resetpass' ? 'block' : 'none'}">

                    <!-- email -->
                    <div class="km-form-item-container">
                        <div class="km-form-item-header-container">
                            <div class="field-error-div"
                                [ngStyle]="{'display':emailPassResetFieldError ? 'inline-block':'none'}">
                            </div>
                            <span class="km-form-item-title-span">Email</span>
                        </div>
                        <div style="width: 100%;">
                            <input class="km-input" (keyup)="inputKeyUpHandler()" maxlength="100" type="text"
                                #userPasswordResetEmailInput name="email">
                        </div>

                    </div>

                    <div style="margin-top:30px;text-align: right;">
                        <div style="width:55px;" class="km-button km-button-positive" (click)="sendResetRequest()"
                            [ngClass]="{'km-button-clicked':passResetButtonOneLoading == true}">
                            <div class="km-btn-spinner"
                                [ngClass]="{'km-btn-spinner-show':passResetButtonOneLoading == true}">
                            </div>
                            <span [ngClass]="{'km-button-span-hide':passResetButtonOneLoading == true}">Reset</span>
                        </div>
                    </div>

                    <div style="text-align: right;display:block;margin-top: 15px;">
                        <span (click)="togglePassReset('signin')"
                            style="font-size:12px;font-weight:500;color:#9144DD;cursor: pointer;">Go back to Sign
                            In</span>
                    </div>

                </div>

                <div [ngStyle]="{'display':currentView == '2fa' ? 'block' : 'none'}">

                    <div style="margin-top: 30px;">
                        <input class="km-input km-2fa-input" autocomplete="off" (keyup)="twoFaEntered('2')"
                            maxlength="1" type="text" id="tfa1" #tfa1>
                        <input class="km-input km-2fa-input" autocomplete="off" (keyup)="twoFaEntered('3')"
                            maxlength="1" type="text" id="tfa2" #tfa2>
                        <input class="km-input km-2fa-input" autocomplete="off" (keyup)="twoFaEntered('4')"
                            maxlength="1" type="text" id="tfa3" #tfa3>
                        <input class="km-input km-2fa-input" autocomplete="off" (keyup)="twoFaEntered('5')"
                            maxlength="1" type="text" id="tfa4" #tfa4>
                        <input class="km-input km-2fa-input" autocomplete="off" (keyup)="twoFaEntered('6')"
                            maxlength="1" type="text" id="tfa5" #tfa5>
                        <input class="km-input km-2fa-input" autocomplete="off" (keyup)="twoFaEntered('6')"
                            maxlength="1" type="text" #tfa6 id="tfa6">
                        <div style="margin-left:4px;opacity:0;transition: opacity 0.23s ease-in-out;"
                            [ngStyle]="{'opacity':tFaButtonOneLoading == true ? '1':'0'}"
                            class="km-btn-spinner-secondary km-btn-spinner-show">
                        </div>
                    </div>

                    <div style="margin-top:30px;">
                        <span style="font-size:12.5px;color:rgb(116, 116, 116);">Didn't receive code?</span> <span
                            style="font-size:12px;font-weight:500;color:#9144DD;margin-left:5px;cursor: pointer;" (click)="resendCode()">Resend</span>
                    </div>

                </div>

            </div>

        </div>


    </div>

    <!-- <div class="glowball" style="top:20%;left:50px;z-index: 1;">
    </div> -->


</div>