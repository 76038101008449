import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiRequestHandlerService {

  constructor(
    private http: HttpClient
  ) { }

    public baseUrl: string = "https://back.kapmates.com" // production
    //public baseUrl: string = "https://localhost:44361" //local


  signInCheck(payload: any): Observable<any> {

    return this.http.post<any>(this.baseUrl + '/user/login', payload, {})

  }



  verify2Fa(code: string, token): Observable<any> {

    const headers = { 'token': token };

    return this.http.post<any>(this.baseUrl + '/user/verify/2fa/' + code, null, { headers })

  }

  //initiates password reset
  resetPassword(payload: any): Observable<any> {

    return this.http.post<any>(this.baseUrl + '/user/reset_password', payload, {})

  }

  checkPassResetToken(token: string): Observable<any> {

    return this.http.get<any>(this.baseUrl + '/user/reset_password/validate_token/' + token, {})

  }

  //updates password to new one
  resetPasswordUpdate(payload: any, resetToken: string): Observable<any> {

    const headers = { 'token': resetToken };

    return this.http.put<any>(this.baseUrl + '/user/reset_password', payload, { headers })

  }

  createNewAccount(payload: any): Observable<any> {

    return this.http.post<any>(this.baseUrl + '/create/user_account', payload, {})

  }

  sendAccessRequest(payload: any): Observable<any> {

    return this.http.post<any>(this.baseUrl + '/add/access_request', payload, {})

  }


}
