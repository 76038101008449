import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiRequestHandlerService } from 'src/app/Services/GeneralServices/api-request-handler.service';
import { CookieHandlingServicesService } from 'src/app/Services/GeneralServices/cookie-handling-services.service';
import { NotificationListComponentComponent } from 'src/app/SharedComponents/notification-list-component/notification-list-component.component';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private apiService: ApiRequestHandlerService,
    private cookieHandler: CookieHandlingServicesService,

  ) {
    this.route.queryParams.subscribe(params => {
      this.resetToken = params['t'];
    });
  }
  public resetToken: string = null;
  public showSignin: boolean = false;
  public showSignInAnimated = false;

  public notificationComponent: NotificationListComponentComponent = new NotificationListComponentComponent();

  ngOnInit(): void {
    //if user id and token cookies are available, then redirect them to in.kapmates.com
    if (this.resetToken == null || this.resetToken == undefined || this.resetToken.length < 2) {
      this.router.navigateByUrl('');
    }
    else {

      this.titleService.setTitle("Kapmates • Reset password");

      //verify reset token
      //send request
      this.apiService.checkPassResetToken(this.resetToken).subscribe({

        next: data => {

          if (data.success == true) {

            this.showSignin = true;
            setTimeout(() => {
              this.showSignInAnimated = true;
            }, 20);

          }
          //success == false
          else {

            this.router.navigateByUrl('');

          }

        },

        error: () => {

          this.router.navigateByUrl('');
        }

      });


    }
  }


  @ViewChild('userNewPasswordInput') userNewPasswordInput: ElementRef;
  @ViewChild('userReNewPasswordInput') userReNewPasswordInput: ElementRef;

  public passOneFieldError: boolean = false;
  public passTwoFieldError: boolean = false;

  public resetButtonOneLoading: boolean = false;

  inputKeyUpHandler() {
    if (this.userNewPasswordInput.nativeElement.value.length > 0) {
      this.passOneFieldError = false;
    }
    if (this.userReNewPasswordInput.nativeElement.value.length > 0) {
      this.passTwoFieldError = false;
    }
  }


  sendResetRequest() {

    let newPassOne: string = this.userNewPasswordInput.nativeElement.value;
    let newPassTwo: string = this.userReNewPasswordInput.nativeElement.value;

    if (newPassOne.length > 0 && newPassTwo.length > 0) {
      if (newPassOne == newPassTwo) {

        if (newPassOne.length > 7) {

          this.resetButtonOneLoading = true;

          this.apiService.resetPasswordUpdate({ password: newPassOne }, this.resetToken).subscribe({

            next: data => {

              if (data.success == true) {

                setTimeout(() => {

                  this.resetButtonOneLoading = false;

                  this.userNewPasswordInput.nativeElement.value = '';

                  this.userReNewPasswordInput.nativeElement.value = '';

                  //sign-in user
                  this.cookieHandler.setCookie('user', data.user_id);
                  this.cookieHandler.setCookie('token', data.token);
                  this.cookieHandler.setCookie('company', data.company_id);

                  window.location.href = 'https://in.kapmates.com';


                }, 700);

              }
              //success == false
              else {
                setTimeout(() => {

                  this.resetButtonOneLoading = false;
                  this.notificationComponent.displayNotification(data.message, 'neg');

                }, 500);
              }

            },

            error: () => {

              setTimeout(() => {

                this.resetButtonOneLoading = false;
                this.notificationComponent.displayNotification("An error occurred!", 'neg');

              }, 200);

            }

          })


        }
        else {
          this.notificationComponent.displayNotification('Password must be at least 8 characters', 'neg');
        }


      }
      else {

        this.notificationComponent.displayNotification('Passwords do not match', 'neg');

      }
    }
    else {

      if (newPassOne.length == 0) {
        this.passOneFieldError = true;
      }
      if (newPassTwo.length == 0) {
        this.passTwoFieldError = true;
      }

    }

  }

}
