import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './SharedComponents/navbar/navbar.component';
import { SignupComponent } from './AuthenticationComponents/signup/signup.component';
import { SigninComponent } from './AuthenticationComponents/signin/signin.component';
import { PasswordResetComponent } from './AuthenticationComponents/password-reset/password-reset.component';
import { PricingPageComponent } from './HomepageComponents/pricing-page/pricing-page.component';
import { SecurityComponent } from './HomepageComponents/security/security.component';
import { VisionComponent } from './HomepageComponents/vision/vision.component';
import { HttpClientModule } from '@angular/common/http';
import { NotificationListComponentComponent } from './SharedComponents/notification-list-component/notification-list-component.component';
import { PhoneNumberInputComponentComponent } from './SharedComponents/phone-number-input-component/phone-number-input-component.component';
import { AddressModuleComponentComponent } from './SharedComponents/address-module-component/address-module-component.component';
import { StandardSearchableDropdownComponent } from './SharedComponents/Dropdown/standard-searchable-dropdown/standard-searchable-dropdown.component';
import { CountryDropdownComponentComponent } from './SharedComponents/Dropdown/country-dropdown-component/country-dropdown-component.component';
import { RequestAccessComponent } from './SharedComponents/request-access/request-access.component';
import { MainPageComponent } from './HomepageComponents/main-page/main-page.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    MainPageComponent,
    SignupComponent,
    SigninComponent,
    PasswordResetComponent,
    PricingPageComponent,
    SecurityComponent,
    VisionComponent,
    NotificationListComponentComponent,
    PhoneNumberInputComponentComponent,
    AddressModuleComponentComponent,
    StandardSearchableDropdownComponent,
    CountryDropdownComponentComponent,
    RequestAccessComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
