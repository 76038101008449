import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public isMobileMenuToggled: boolean = false;
  public isMobileMenuToggledAnimation: boolean = false;

  toggleNavBar(stat: boolean) {

    if (stat == true) {
      this.isMobileMenuToggled = true;

        setTimeout(() => {
            this.isMobileMenuToggledAnimation = true;
        }, 30);

    }
    else {
      
      this.isMobileMenuToggledAnimation = false;   

        setTimeout(() => {
           this.isMobileMenuToggled = false;
        }, 30);

    }

  }

}
