export class EmailValidatorClass {

  validate(input: string): boolean {

      let tld: string = "";
      let user: string = "";

      let totalAtSymbols: number = 0;
      for (let index = 0; index < input.length; index++) {
          const element = input[index];
          if (element == "@") {
              totalAtSymbols++;
          }
      }

      if (totalAtSymbols == 1) {
          let splitbyat = (input.trim()).split("@");
          if (splitbyat.length = 2) {

              user = splitbyat[0]

              if (user.length > 0) {
                  let splitbydot = (splitbyat[1]).split(".");

                  if (splitbydot.length == 2) {
                      tld = splitbydot[splitbydot.length - 1]

                      if (tld.length > 1) {
                          return true;
                      }
                  }


              }

          }
      }


      return false;
  }

}
