import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookieHandlingServicesService {

  constructor(
    private cookieService: CookieService,

  ) { }


  getCookie(name: string): string {
    return this.cookieService.get(name);
  }

  setCookie(name: string, value: string) {
    //set cookie
    let todayDate: Date = new Date();
    let expirationDate: Date = new Date();
    expirationDate.setDate(todayDate.getDate() + 14);

    this.cookieService.set(name, value, expirationDate, '/', '.kapmates.com'); //production
    //this.cookieService.set(name, value, expirationDate, '/', 'localhost'); //local
    
  }

  deleteCookie(name: string) {
    this.cookieService.delete(name, '/');
  }

  checkCookie(name): boolean {
    if (this.cookieService.check(name) == true) {
      return true;
    }
    else {
      return false;
    }
  }


}
