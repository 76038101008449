import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RequestAccessComponent } from 'src/app/SharedComponents/request-access/request-access.component';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent implements OnInit {

  constructor(
    private titleService: Title,
  ) { }

  public isLoaded: boolean = false;

  ngOnInit(): void {
    this.titleService.setTitle("Corporate Card & Spend Management - Reimagined");

    setTimeout(() => {
      this.isLoaded = true;
    }, 2);

  }

  @ViewChild('requestAccessComp') requestAccessComp: RequestAccessComponent;
  toggleAccessRequest() {
    this.requestAccessComp.toggleAccessRequest(true);
  }

  public currentObjectToggled: string = 'group';
  public currentObjectToggledSub: string = 'group';
  public currentObjectToggledSubAnimate: string = 'group';

  toggleObject(view: string) {
    this.currentObjectToggledSub = view;
    setTimeout(() => {
      this.currentObjectToggledSubAnimate = view;
    }, 2);
    setTimeout(() => {
      this.currentObjectToggled = view;
    }, 2);
  }


  public isWatchVid: boolean = false

  public toggleWatchVid(stat: boolean) {
    this.isWatchVid = stat;
  }

  public currentYear: Date = new Date;

}
