<div>
    <app-navbar></app-navbar>

    <div class="main-holder">

        <div style="text-align: center;max-width:1300px;margin:auto;">

            <div [ngStyle]="{'opacity':isLoaded == true ? '1':'0'}"
                style="max-width:1000px;margin:auto;margin-bottom: 10px;opacity:0;transition: opacity 0.25s ease-in-out">
                <span class="pricing-header">Simple Pricing</span>
            </div>

            <div class="pricing-main-window" [ngStyle]="{'opacity':isLoaded == true ? '1':'0'}"
                style="opacity:0;transition: opacity 0.25s ease-in-out;">

                <div style="min-height:400px;margin-left:15px;margin-right:15px;">

                    <div class="col-md-12 col-lg-12" style="text-align: left;">

                        <span style="font-size:30px!important;color:#fff;" class="tagline-span">Free</span><br><br>
                        <span style="color:#fff;font-size:18px;"> for teams with up to 3 people and 9 cards</span>

                        <div style="margin-top:10px;">
                            <span style="color:rgb(224, 224, 224);font-size:12px;">We charge a one-time <b>$5</b> fee
                                for each physical card shipped.</span>
                        </div>
                        <hr style="border:solid 0.2px rgba(243, 243, 243, 0.041);margin-top:25px;margin-bottom:20px;">

                        <span style="font-size:30px!important;" class="km-text-gradient tagline-span">$7</span>
                        <br> <br>
                        <span style="color:#fff;font-size:16px;line-height: 1.5;"> per person, per month, <span
                                style="color: rgba(233, 233, 233, 0.603);">after free limits are exceeded</span></span>
                        <div style="margin-top:10px;">
                            <span style="color:rgb(224, 224, 224);font-size:12px;">Physical card fees are
                                <b>waived</b>.</span>
                        </div>

                        <hr style="border:solid 0.2px rgba(243, 243, 243, 0.041);margin-top:25px;margin-bottom:20px;">

                        <input id="calc_toggle" class="km-toggle" type="checkbox">
                        <label for="calc_toggle" class="km-lbl-toggle"
                            style="color:rgb(255, 255, 255);font-size:14px;">Calculate pricing</label>
                        <div class="km-collapsible-content">
                            <div class="km-toggle-holder-div">

                                <div style="width:100%;margin-top:15px;">
                                    <div style="display: inline-block;margin-right:20px;">
                                        <input class="km-input"
                                            style="width:90px;background-color:#fff;text-align:center;" maxlength="50"
                                            (keyup)="pricingCalc()" (change)="pricingCalc()" placeholder="30"
                                            type="number" #employeesCount name="1">
                                        <br>
                                        <span style="font-size:11px;color:rgb(179, 179, 179);">Total People</span>
                                    </div>

                                    <div style="display: inline-block;">
                                        <input class="km-input"
                                            style="width:90px;background-color:#fff;text-align:center" maxlength="50"
                                            (keyup)="pricingCalc()" (change)="pricingCalc()" placeholder="120"
                                            type="number" #totalCardsCount name="1">
                                        <br>
                                        <span style="font-size:11px;color:rgb(179, 179, 179);">Total Cards</span>
                                    </div>
                                </div>

                                <div style="margin-top: 20px;">
                                    <span style="font-size:30px!important;"
                                        class="km-text-gradient tagline-span">{{calculatedPrice
                                        | currency:'USD'}} </span> <span *ngIf="calculatedPrice > 0"
                                        style="color:#fff;font-size:16px;"> per month</span>
                                </div>


                            </div>
                        </div>


                        <hr style="border:solid 0.2px rgba(243, 243, 243, 0.041);margin-top:25px;margin-bottom:20px;">

                        <div style="margin-top:15px;">
                            <div (click)="toggleAccessRequest()" class="btn-request-access">
                                <span>Request Access</span>
                            </div>
                        </div>

                    </div>


                </div>


            </div>


        </div>

        <div class="side-des-left side-des-ver" style="left:-20px!important">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="492" viewBox="0 0 34 492" fill="none">
                <rect width="34" height="322" rx="17" fill="#fff" />
                <circle cx="17" cy="367" r="17" fill="#FFD384" />
                <circle cx="17" cy="421" r="17" fill="#B178F6" />
                <circle cx="17" cy="475" r="17" fill="#9DE4FF" />
            </svg>
        </div>

    </div>

    <app-request-access #requestAccessComp></app-request-access>

</div>