import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailValidatorClass } from 'src/app/Services/GeneralClasses/email-validator-class.service';
import { ApiRequestHandlerService } from 'src/app/Services/GeneralServices/api-request-handler.service';
import { CookieHandlingServicesService } from 'src/app/Services/GeneralServices/cookie-handling-services.service';
import { AddressModuleComponentComponent } from 'src/app/SharedComponents/address-module-component/address-module-component.component';
import { NotificationListComponentComponent } from 'src/app/SharedComponents/notification-list-component/notification-list-component.component';
import { PhoneNumberInputComponentComponent } from 'src/app/SharedComponents/phone-number-input-component/phone-number-input-component.component';
import tippy from 'tippy.js';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  constructor(
    private titleService: Title,
    private apiService: ApiRequestHandlerService,
    private cookieHandler: CookieHandlingServicesService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.queryParams.subscribe(params => {
      this.signupTokenParam = params['t'];
    });
  }

  public showSignin: boolean = false;
  public showSignInAnimated = false;

  public notificationComponent: NotificationListComponentComponent = new NotificationListComponentComponent();

  public signupTokenParam: string = null;

  ngOnInit(): void {

    this.titleService.setTitle("Kapmates • Sign Up");

    if (this.signupTokenParam == null && this.cookieHandler.checkCookie('signup-token') == false) {
      this.router.navigateByUrl('');
    }
    else {

      if (this.signupTokenParam != null) {
        this.signupToken = this.signupTokenParam;
        this.cookieHandler.setCookie('signup-token', this.signupToken);
      }
      else {
        this.signupToken = this.cookieHandler.getCookie('signup-token');
      }

      //check signup token and proceed accordingly
      this.showSignin = true;
      setTimeout(() => {
        this.showSignInAnimated = true;
      }, 20);

    }


  }


  ngAfterViewInit() {
    tippy('.km-tooltip',
      { allowHTML: true, delay: [500, 50], placement: 'bottom', theme: 'km-tippy-tip-dark' });
  }

  public currentView: string = 'basic'; // basic || holder || pass

  handlePhoneNumberKeyin(e) {
    if (e == true) {
      this.checkBasicInfoChange();
    }

  }

  public isAboutPassed: boolean = false;
  public isHolderPassed: boolean = false;
  public isPasswordPassed: boolean = false;

  navigateWindow(to: string, from: string) {

    if (from == 'basic') {

      if (this.CheckUserMainIfo() == true) {
        this.currentView = to;
        this.isAboutPassed = true;
      }
      else {
        this.isAboutPassed = false;
      }

    }
    else if (from == 'holder') {
      if (to == 'basic') {
        this.currentView = to;
      }
      else {
        if (this.checkUserHolderInfo() == true) {
          this.currentView = to;
          this.isHolderPassed = true;
        }
        else {
          this.isHolderPassed = false;
        }
      }

    }

    else if (from == 'pass') {
      if (to == 'holder') {
        this.currentView = to;
      }
    }

  }


  @ViewChild('physicalCardName') physicalCardName: ElementRef;
  @ViewChild('billingAddressComponent') billingAddressComponent: AddressModuleComponentComponent;


  public totalHolderPassed: number = 0;
  checkUserHolderInfo() {

    this.totalHolderPassed = 0;

    if (this.physicalCardName.nativeElement.value.length > 0) {
      this.nameOnCard = this.physicalCardName.nativeElement.value;
      this.totalHolderPassed++;
    }
    else {
      this.nameOnCard = this.firstName + ' ' + this.lastName;
      this.physicalCardName.nativeElement.value = this.nameOnCard;
      this.totalHolderPassed++;
    }

    let billingAddress = this.billingAddressComponent.collectData();
    if (billingAddress.IsComplete == true) {
      this.billingAddress = billingAddress.Address;
      this.totalHolderPassed++;
    }

    if (this.totalHolderPassed == 2) {
      return true;
    }
    else {
      return false;
    }

  }


  public firstName: string;
  public lastName: string;
  public displayName: string;
  public email: string;
  public phoneNumber: string;
  public phoneCountryCode: string;
  public phoneCountryText: string;
  public nameOnCard: string;
  public billingAddress: any = {};

  @ViewChild('firstnameInput') firstnameInput: ElementRef;
  @ViewChild('lastnameInput') lastnameInput: ElementRef;
  @ViewChild('displayNameInput') displayNameInput: ElementRef;
  @ViewChild('emailInput') emailInput: ElementRef;
  @ViewChild('userPhoneComponent') userPhoneComponent: PhoneNumberInputComponentComponent;

  @ViewChild('userPasswordInput') userPasswordInput: ElementRef;
  @ViewChild('userConfirmNewPasswordInput') userConfirmNewPasswordInput: ElementRef;

  public totalAboutPassed: number = 0;

  public legalNameError: boolean = false;
  public emailError: boolean = false;
  public phoneError: boolean = false;
  public passOneFieldError: boolean = false;
  public passTwoFieldError: boolean = false;

  CheckUserMainIfo() {

    let emailValidator: EmailValidatorClass = new EmailValidatorClass();

    this.totalAboutPassed = 0;

    if (this.firstnameInput.nativeElement.value.length > 0) {
      this.totalAboutPassed++;
      this.firstName = this.firstnameInput.nativeElement.value;
    }
    else {
      this.legalNameError = true;
    }

    if (this.lastnameInput.nativeElement.value.length > 0) {
      this.totalAboutPassed++;
      this.lastName = this.lastnameInput.nativeElement.value;
    } else {
      this.legalNameError = true;
    }

    if (this.emailInput.nativeElement.value.length > 0) {

      this.email = this.emailInput.nativeElement.value;
      if (emailValidator.validate(this.email) == true) {
        this.totalAboutPassed++;
      }
      else {
        this.email = null;
        this.emailError = true;
      }
    }
    else {
      this.emailError = true;
    }

    if (this.displayNameInput.nativeElement.value.length > 0) {
      this.displayName = this.displayNameInput.nativeElement.value;
    }
    else {
      this.displayName = this.firstName + ' ' + this.lastName;
    }

    let phoneNumber = this.userPhoneComponent.collectData();
    if (phoneNumber.IsComplete == true) {
      this.phoneNumber = phoneNumber.Phone;
      this.phoneCountryCode = phoneNumber.CountryCodeNumber;
      this.phoneCountryText = phoneNumber.CountryCode;
      this.totalAboutPassed++;
    }
    else {
      this.phoneError = true;
    }

    if (this.totalAboutPassed == 4) {
      return true;
    }
    else {
      return false;
    }

  }

  getStateText(): string {
    if (this.currentView == 'basic') {
      return "About you";
    }
    else if (this.currentView == 'holder') {
      return "For your cards";
    }
    else if (this.currentView == 'pass') {
      return "Password";
    }
    else {
      return "Let's get you set up!";
    }
  }

  checkBasicInfoChange() {
    if (this.firstnameInput.nativeElement.value.length > 0) {
      this.legalNameError = false;
    }

    if (this.lastnameInput.nativeElement.value.length > 0) {
      this.legalNameError = false;
    }

    if (this.emailInput.nativeElement.value.length > 0) {
      this.emailError = false;
    }

    if (this.userPhoneComponent.collectData().IsComplete == true) {
      this.phoneError = false;
    }

    if (this.userPasswordInput.nativeElement.value.length > 0) {
      this.passOneFieldError = false;
    }
    if (this.userConfirmNewPasswordInput.nativeElement.value.length > 0) {
      this.passTwoFieldError = false;
    }

  }


  public signupToken: string;
  public createAccountButtonOneLoading: boolean = false;

  createAccount() {

    let newPassOne: string = this.userPasswordInput.nativeElement.value;
    let newPassTwo: string = this.userConfirmNewPasswordInput.nativeElement.value;

    if (newPassOne.length > 0 && newPassTwo.length > 0) {

      if (newPassOne == newPassTwo) {

        if (newPassOne.length > 7) {

          this.isPasswordPassed = true;
          this.createAccountButtonOneLoading = true;

          if (this.displayName == null) {
            this.displayName = this.firstName + ' ' + this.lastName;
          }

          let payload: any = {
            access_code: this.signupToken,
            first_name: this.firstName,
            last_name: this.lastName,
            display_name: this.displayName,
            phone: this.phoneNumber,
            phone_country_code: this.phoneCountryText,
            phone_country_code_number: this.phoneCountryCode,
            password: newPassOne,
            name_on_card: this.nameOnCard,
            billing_address: this.billingAddress,
            shipping_address: this.billingAddress
          }

          this.apiService.createNewAccount(payload).subscribe({

            next: data => {

              if (data.success == true) {

                setTimeout(() => {

                  this.createAccountButtonOneLoading = false;

                  this.cookieHandler.deleteCookie('signup-token');

                  this.cookieHandler.setCookie('user', data.user_id);
                  this.cookieHandler.setCookie('token', data.token);

                  if (data.company_id != null && data.company_id != "") {
                    this.cookieHandler.setCookie('company', data.company_id);
                  }

                  window.location.href = 'https://in.kapmates.com';

                }, 500);

              }
              //success == false
              else {
                setTimeout(() => {

                  this.createAccountButtonOneLoading = false;
                  this.notificationComponent.displayNotification(data.message, 'neg');

                }, 300);
              }

            },

            error: () => {

              this.createAccountButtonOneLoading = false;
              this.notificationComponent.displayNotification("An error occurred!", 'neg');

            }

          })


        }
        else {
          this.notificationComponent.displayNotification('Password must be at least 8 characters', 'neg');
        }

      }
      else {
        this.notificationComponent.displayNotification('Passwords do not match', 'neg');
      }

    }
    else {
      if (newPassOne.length == 0) {
        this.passOneFieldError = true;
      }
      if (newPassTwo.length == 0) {
        this.passTwoFieldError = true;
      }
    }

  }

}
