<div class="km-navbar">

    <div class="km-navbar-inner">

        <div class="nav-logo-holder">
            <div class="nav-logo-inner-holder">
                <a routerLink="/">
                    <img title="Kapmates" draggable="false" class="nav-logo"
                        src="https://kapmatesfiles.sfo3.cdn.digitaloceanspaces.com/web_assets/kaplogo.png"
                        alt="Kapmates logo"></a>
            </div>
        </div>

        <div class="nav-menu-holder-bg">

            <ul style="display:flex;">

                <!-- pricing -->
                <!-- <li>
                <a class="nav-link" routerLinkActive="nav-link-active" routerLink="/pricing">
                    <span class="nav-link-default">Pricing</span>
                </a>
            </li> -->

                <!-- security -->
                <li>
                    <a class="nav-link" routerLinkActive="nav-link-active" routerLink="/security">
                        <span class="nav-link-default">Security</span>
                    </a>
                </li>


                <!-- vision -->
                <!-- <li>
                    <a class="nav-link" routerLinkActive="nav-link-active" routerLink="/vision">
                        <span class="nav-link-default">Vision</span>
                    </a>
                </li> -->


                <!-- signin -->
                <li>
                    <a class="nav-link sign-in" routerLink="/signin">
                        <span class="nav-link-signin">Sign In</span>
                    </a>
                </li>


            </ul>

        </div>

        <div class="nav-menu-holder-sm">
            <ul class="nav-sm-screen">
                <li>
                    <div (click)="toggleNavBar(true)" class="nav-menu-sm-toggler">
                        <div style="position:absolute;  top:5.4px; left:9.5px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 20 15"
                                fill="none">
                                <path
                                    d="M18.1595 0H1.84051C0.825664 0 0 0.825625 0 1.84176C0 2.8566 0.825664 3.68227 1.84051 3.68227H18.1595C19.1743 3.68227 20 2.8566 20 1.84176C20 0.825625 19.1743 0 18.1595 0Z"
                                    fill="#FFF" />
                                <path
                                    d="M18.1595 5.64844H1.84051C0.825664 5.64844 0 6.47406 0 7.49019C0 8.50504 0.825664 9.3307 1.84051 9.3307H18.1595C19.1743 9.3307 20 8.50504 20 7.49019C20 6.47406 19.1743 5.64844 18.1595 5.64844V5.64844Z"
                                    fill="#FFF" />
                                <path
                                    d="M18.1595 11.2968H1.84051C0.825664 11.2968 0 12.1224 0 13.1385C0 14.1534 0.825664 14.979 1.84051 14.979H18.1595C19.1743 14.979 20 14.1534 20 13.1385C20 12.1224 19.1743 11.2968 18.1595 11.2968Z"
                                    fill="#FFF" />
                            </svg>
                        </div>
                    </div>
                </li>
            </ul>

            <!-- mobile menu -->
            <div class="nav-mobile-menu" [ngClass]="{'nav-mobile-menu-open':isMobileMenuToggledAnimation == true}"
                [ngStyle]="{'display':isMobileMenuToggled == true ? 'block':'none'}">

                <div style="position: relative;">
                    <div style="position:absolute;right:6px;top: 7.5px;">
                        <div (click)="toggleNavBar(false)" class="nav-menu-sm-toggler-close">
                            <div style="position:absolute;top:7.2px; left:11.5px;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 25 25"
                                    fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M0.774279 0.774271C0.160405 1.38815 0.160404 2.38343 0.774279 2.99731L10.0908 12.3139L0.774285 21.6304C0.16041 22.2443 0.16041 23.2396 0.774285 23.8535C1.38816 24.4673 2.38345 24.4673 2.99732 23.8535L12.3139 14.5369L21.6304 23.8535C22.2443 24.4673 23.2396 24.4673 23.8535 23.8535C24.4673 23.2396 24.4673 22.2443 23.8535 21.6304L14.5369 12.3139L23.8535 2.99731C24.4673 2.38343 24.4673 1.38815 23.8535 0.774273C23.2396 0.160399 22.2443 0.160398 21.6304 0.774273L12.3139 10.0908L2.99731 0.774271C2.38344 0.160397 1.38815 0.160397 0.774279 0.774271Z"
                                        fill="#00002C" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="position: relative;margin-top:60px;">

                    <!-- <a routerLink="/pricing">
                        <div class="mobile-menu-item">
                            <span class="mobile-menu-link">Pricing</span>
                        </div>
                    </a> -->

                    <a routerLink="/security">
                        <div class="mobile-menu-item">
                            <span class="mobile-menu-link">Security</span>
                        </div>
                    </a>

                    <!-- <a routerLink="/vision">
                        <div class="mobile-menu-item">
                            <span class="mobile-menu-link">Vision</span>
                        </div>
                    </a> -->


                    <a routerLink="/signin">
                        <div class="mobile-menu-item">
                            <span class="mobile-menu-link">Sign In</span>
                        </div>
                    </a>


                </div>

            </div>

        </div>

    </div>

</div>